@import '../../sass/fontFamilies.scss';

.avatar {
  // background: var(--screenz-main);
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.unknownAvatar {
    background: transparent;
    border: 1px dashed var(--grey-6);
  }
  .profilePic {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    &.small {
      height: 38px;
      width: 38px;
    }
  }
  .initials {
    &.medium {
      height: 100%;
      width: 100%;
      border: 3px solid #615ae2;
    }
    &.small {
      height: 100%;
      width: 100%;
    }
    border-radius: inherit;
    background: #7973e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $InterRegular;
    font-size: 15px;
    color: var(--white);
    text-transform: uppercase;
  }
}
