@import '../../sass/fontFamilies.scss';

%avatarAndName {
  display: flex;
  align-items: center;
  margin: 13px 0;

  cursor: pointer;
  .name {
    margin-left: 10px;
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
    // text-transform: capitalize;
  }
  .email {
    margin-left: 10px;
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-4);
  }

  .owner {
    margin-left: auto;
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  .canEdit {
    display: flex;
    align-items: center;
    margin-left: auto;
    span {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    img {
      width: 10px;
      margin-left: 14px;
    }
  }
}
.dropDown {
  border: 0;
  height: 28px;
}
.shareWrapper {
  border-top: var(--border);
  // display: none;
  .heading {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #33373a;
    margin-bottom: 23px;
  }
  .subHeading {
    font-family: $InterMedium;
    height: 28px;
    flex-grow: 1;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #8a8daa;
    display: flex;
    align-items: center;
  }

  .usersIcon {
    border-radius: 23px;
    width: 43px;
    height: 43px;
    flex-grow: 0;
    margin: 0px 10px 0 0;
    padding: 11px 0px 11px 0px;
    background-color: var(--screenz-main);
  }

  .avatarGroup {
    margin-bottom: 15px;
  }

  // .shareBtn {
  //   font-family: $InterMedium;
  //   font-size: 14px;
  //   font-weight: 500;
  //   color: var(--screenz-main);
  //   border: 1.2px solid #7973e6;
  // }
  .disableBtn {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: #acaec5;
    margin-top: 30px;
    border: solid 1px var(--grey-6);

    &.disable {
      background-color: transparent;
      opacity: 0.4;

      &:hover {
        color: #acaec5;
      }
    }
  }
}
.shareModal {
  width: 100%;
  max-width: 500px;
  @media screen and (min-width: 800px) {
    width: 88vw;
  }
  .searchUsers {
    margin: 0 -1.25rem;
    padding: 10px 20px 0 20px;
    border-bottom: var(--border);
    .searchDropdown {
      border: 1.5px solid var(--grey-6);
      // margin-bottom: 20px;
      padding: 0 10px;
      height: 48px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      transition: 300ms;
      z-index: 1000;
      position: relative;
      cursor: pointer;
      &.focused {
        border: 1.5px solid var(--screenz-main);
        box-shadow: 0 0 1px 4px #d5d3eb;
        border-bottom: none;
      }
      .search {
        flex-grow: 1;
        border: 0;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-4);
        height: 45px;
        &:focus {
          outline: none;
        }
      }

      .foundList {
        border: 1.5px solid var(--screenz-main);
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        background: #fff;
        border-radius: 5px;
        padding: 0 15px;
        max-height: 300px;
        overflow: auto;
        .avatarAndName {
          display: flex;
          @extend %avatarAndName;
        }
      }
    }
    .accessMessage {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px 2px;

      .message {
        font-family: $InterRegular;
        font-size: 13.5px;
        line-height: 1.4;
        margin-left: 1rem;
        color: var(--grey-4);
      }
    }
  }

  .usersList {
    margin: 0 -5px;
    min-height: 250px;
    max-height: 300px;
    overflow: auto;
    .avatarAndName {
      @extend %avatarAndName;
    }
  }
}

.optionPopover {
  background: #4c4ab4;
  border-radius: 8px;
  min-width: 100px;
  right: 0px;
  // padding: 13px;
  // cursor: pointer;
  .logout {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    padding: 5px 15px;
    // margin-bottom: 0.8rem;
    font-family: $InterRegular;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    &:hover {
      background: var(--screenz-main-hover);
      border-radius: 8px;
      // margin-
    }

    &:last-child {
      &:hover {
        // background: var(--screenz-main-hover);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:first-child {
      &:hover {
        // background: var(--screenz-main-hover);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.canEdit {
  display: flex;
  font-family: $InterRegular;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  &.show {
    background: #3b369a;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
  .profilePic {
    margin-right: 8px;
  }
  .username {
    font-size: 13px;
    font-family: $InterRegular;
    color: #c8c7e8;
  }
  .expandArrowDown {
    margin: 0 5px;
  }
  display: flex;
  align-items: center;
  span {
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  img {
    width: 10px;
    margin-left: 14px;
  }
}
