@import '../../../../sass/fontFamilies.scss';
.integrationsMainContent {
  flex-grow: 1;
  overflow: auto;
  padding: 0 10px;
  padding-top: 30px;
  @media screen and (max-width: 800px) {
    display: none;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .screenzBtn {
      @media screen and (min-width: 800px) {
        width: 20%;
      }
      margin-bottom: 0.5rem;
    }

    .badgeContainer {
      display: flex;
      flex-direction: column;
      width: 20%;
      padding-bottom: 1rem;

      .badgeText {
        margin-top: 2px;
        font-size: 13px;
        font-family: $InterMedium;
        font-weight: 500;
        color: #354052;
      }
    }

    .contentData {
      font-size: 15px;
      font-family: $InterRegular;
      margin-bottom: 10px;
      color: #5b5d70;
    }
    // grid-template-columns: 45% 55%;
  }
}
