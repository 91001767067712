@import '../../../../../sass/fontFamilies.scss';

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #fff;
  border-bottom: 1.3px solid var(--grey-6);

  cursor: pointer;
  border-radius: 5px 5px 0 0;
  &:hover {
    background: var(--screenz-main-hover);
  }
  .avatar {
    margin-right: 5px;
  }
  > p {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: #111216;
    word-wrap: break-word;
    width: 10vw;
  }
}
