@import '../../../sass/fontFamilies.scss';

.playlistPreview {
  height: 100%;
  .drawerHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    height: 72px;
    background: var(--grey-7);
    border-radius: 8px 0 0 0;
    border-bottom: solid 1px var(--grey-6);
    .colOne {
      display: flex;
      align-items: center;

      .previewHeading {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--grey-3);
        margin-left: 10px;
      }
    }
    .colTwo {
      display: flex;
      align-items: center;

      .refreshIcon {
        width: 40px;
        height: 40px;
        border: 1px solid var(--grey-5);
        padding: 8px;
        background: var(--grey-7);
        border-radius: 8px;
        cursor: pointer;
        margin-right: 15px;

        &.disabled {
          opacity: 0.5;
          cursor: wait;
        }
        img {
          &.refreshAnimation {
            animation: rotate 1s linear infinite;
            @keyframes rotate {
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
      .closeIcon {
        cursor: pointer;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 42px;
    flex-grow: 1;
    overflow: auto;
    height: 85%;
    &.playlistPreview {
      padding-top: 20px;
    }
    .contentPreview {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      img,
      video {
        width: 100%;
      }
    }
    .playlistPreview {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      width: 100%;
      img,
      video,
      iframe {
        width: 100%;
      }
      iframe {
        height: 35vh;
      }
    }
  }
}
