@import '../../sass/fontFamilies.scss';

.heading {
  font-family: $InterMedium;
  font-size: 15px;
  color: var(--grey-1);
  font-weight: 500 !important;
}
.listContainer {
  border: var(--border);
  background: var(--white);
  border-radius: 10px;

  margin: 15px 20px;
  margin-bottom: 5rem;

  padding-bottom: none;
  @media screen and (min-width: 800px) {
    padding-bottom: 6px;
    border-radius: 10px;
  }

  .listHeader {
    grid-template-columns: 23% 40% 20% 14%;
    border-bottom: var(--border);
    display: none;
    padding: 0 20px;
    @media screen and (min-width: 800px) {
      display: grid;
    }
    p {
      font-family: $TTInterphasesMedium;
      color: #7f8fa4;

      font-size: 14px;
      font-weight: 500;
      padding: 20px 0;
    }
    p:nth-child(1) {
      padding-left: 30px;
    }
  }

  .bodyList {
    // max-height: fit-content;
    // overflow-y: auto;
    // max-height: 44vh;
    // overflow-y: auto;

    .content {
      padding: 5px 20px;
      grid-template-columns: 23% 40% 20% 14% 4%;
      align-items: center;
      height: 45px;
      border-bottom: var(--border);
      cursor: pointer;
      display: flex;
      @media screen and (min-width: 800px) {
        display: grid;
        border-bottom: var(--border);
      }
      @media screen and (max-width: 800px) {
        padding: 5px 20px;
        display: flex;
        // grid-template-columns: 1fr 1fr 1fr;

        // width: 87vw;
      }
      &:hover {
        background: var(--screenz-main-hover);
        .morePopupContainer {
          display: block;
          .moreBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 300ms;
            width: 30px;
            .icon {
              path {
                fill: var(--grey-4);
              }
            }

            &:hover {
              background: var(--grey-6);
              border-radius: 5px;
              .icon {
                path {
                  fill: var(--grey-3);
                }
              }
            }
          }

          // @media screen and (min-width: 800px) {
          //   display: none;
          // }
        }
      }
      .checkbox {
        margin-right: 8px;
      }

      &:last-child {
        margin-bottom: auto;
        // border-bottom: none;
        @media screen and (max-width: 800px) {
          // display: grid;
          border-bottom: none;
        }
      }

      .badgeContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 30px;

        @media screen and (max-width: 800px) {
          display: none;
        }
        .badgeText {
          margin-top: 2px;
          font-size: 14px;
          font-family: $InterMedium;
          font-weight: 500;
          color: var(--grey-2);
        }
      }
      .ownerName {
        @media screen and (max-width: 800px) {
          margin-right: 4rem;
          width: 12vw;
        }
      }
      .previewAndMeta {
        display: flex;
        align-items: center;
        @media screen and (max-width: 800px) {
          flex-grow: 1;
        }

        .contentNameAndLength {
          width: calc(100% - 80px);

          @media screen and (max-width: 800px) {
            width: calc(100% - 80px);
          }
          .contentName {
            font-family: $InterRegular;
            font-size: 13px;
            color: #33373a;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            display: block;
            @media screen and (max-width: 800px) {
              max-width: 40vw;
            }
          }
          .contentMeta {
            font-family: $InterRegular;
            font-size: 13px;
            color: #33373a;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            display: block;
          }
          .url {
            font-family: $InterRegular;
            font-size: 13px;
            color: #33373a;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            display: block;
            @media screen and (max-width: 800px) {
              max-width: 40vw;
            }
          }

          > p {
            width: 75%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            color: #7f8fa4;
            @media screen and (max-width: 800px) {
              width: 100px !important;
              overflow-x: hidden;
            }
          }
        }
      }
      .modifiedOn {
        // text-transform: capitalize;
        font-family: $InterRegular;
        font-size: 14px;
        color: var(--grey-6);
        display: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (min-width: 800px) {
          display: unset;
        }
      }
      .modifiedOn {
        color: var(--grey-2);
      }

      .morePopupContainer {
        // display: none;
        position: relative;
        z-index: 9;
        .popover {
          right: 0;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
          &.last {
            top: -40px;
          }
        }
        .morePopup {
          right: 0;
          display: flex;
          // align-items: center;
          flex-direction: column;
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.36;
          color: var(--grey-1);

          &:hover {
            :last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            :first-child {
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;
            }
          }

          .option {
            padding: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            &.disable {
              opacity: 0.5;
            }
            img {
              width: 30px;
              height: 24px;
              margin-right: 5px;
            }
            &:hover {
              background: var(--screenz-main-hover);
            }
          }
          &.webContentMutation {
            .option {
              margin-bottom: 0;
            }
          }
        }
        .moreBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 300ms;
          width: 30px;
          .icon {
            path {
              fill: var(--grey-4);
            }
          }

          &:hover {
            background: var(--grey-6);
            border-radius: 5px;
            .icon {
              path {
                fill: var(--grey-3);
              }
            }
          }
          @media screen and (min-width: 800px) {
            display: none;
          }
        }
      }
    }
  }
}
