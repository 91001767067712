@import '../../../../../../../sass/fontFamilies.scss';
.currentTime {
  border: dashed 1.5px rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  height: 5.35vw;
  min-width: 10vw;

  .meetingContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .subjectInfo {
      display: flex;
      align-items: baseline;
      padding-top: 0.6vw;
      justify-content: space-between;

      .meetingInfoAndDetails {
        display: flex;
        align-items: baseline;
        .meetingInfo {
          font-size: 19.8px;
          font-size: 1.4vw;
          font-family: $InterBold;

          color: #32ac60;
          margin-right: 4px;

          &.ongoing {
            color: #d8354b;
          }
        }

        .roomDetails {
          color: rgba(255, 255, 255, 0.65);
          // font-size: 10.9px;
          font-size: 0.8vw;
          font-family: $InterRegular;
        }
      }

      .meetingTime {
        font-family: $InterBold;
        // font-size: 19.8px;
        font-size: 1.38vw;
        font-weight: 500;
        color: var(--white);
        line-height: 1;
        color: #eeefef;
        margin-bottom: 0.36vw;
        // margin-bottom: 5px;
      }
    }
    .description {
      font-family: $InterRegular;
      // font-size: 13.5px;
      font-size: 0.9vw;
      font-weight: 500;
      color: var(--white);
      line-height: 1;
      margin-bottom: 5px;
      margin-bottom: 0.36vw;
    }
    .orgainzerContainer {
      display: flex;
      justify-content: space-between;
      .organizer {
        font-family: $InterRegular;
        font-size: 10.9px;
        // font-size: 0.75vw;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65);
        line-height: 1;
      }
      .subject {
        display: flex;
        .heading {
          font-family: $InterRegular;
          // font-size: 10.9px;
          font-size: 0.8vw;
          padding-left: 2px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.65);
          line-height: 1;
        }
      }
    }
  }

  .logoHolder {
    width: 30%;
    height: 100%;
    max-height: 70px;
    padding: 0px 0px 10px 0px;
    img {
      // width: 100%;
      height: 100%;
    }
  }
  &.showActive {
    border: solid 2px #4aacdd;
  }
  .time {
    font-family: $InterBold;
    // font-size: 30.2px;
    font-size: 2.08vw;
    font-weight: 500;
    color: var(--white);
    text-align: start;

    &.rightAligned {
      text-align: end;
    }
  }
  .date {
    font-family: $InterRegular;
    // font-size: 11.5px;
    font-size: 0.78vw;
    color: var(--white);
    text-align: start;

    &.rightAligned {
      text-align: end;
    }
  }
}
