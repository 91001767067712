@import '../../sass/fontFamilies.scss';
.foldersWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  &.withList {
    padding: 15px 10px 0 10px;
    @media screen and (min-width: 800px) {
      padding: 15px 20px 0 20px;
    }
  }

  .recentlyShared {
    font-family: $InterMedium;
    font-size: 13px;
    color: var(--grey-1);
  }
}
