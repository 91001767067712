@import '../../../../../../sass/fontFamilies.scss';

.eventContainer {
  margin-top: 40px;
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 1;
  width: 100%;
  height: 40vw;
  @media screen and (min-width: 1200px) {
    height: 40vw;
  }
  .main {
    position: relative;
    height: 100%;
    @media screen and (max-height: 660px) {
      height: 100%;
      // margin-bottom: 1rem;
    }
    .eventBgImg {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .eventDetails {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      // background-image: linear-gradient(226deg, #000, transparent 300%);
      background-blend-mode: darken;
      background-color: rgba(21, 22, 33, 0.9);
      // background-image: linear-gradient(to bottom, #1e6143, #1e6143);
      padding: 1.4vw;
      display: flex;
      justify-content: space-around;
      // flex-direction: column;
      opacity: 0.9;

      &.ongoing {
        background: #000;
        background-image: linear-gradient(226deg, #000, transparent 300%);
        // background-image: linear-gradient(226deg, #d8354b, transparent 700%);
      }
      .sectionOne {
        width: 50%;
        padding-right: 0.7vw;
        // height: 100%;

        .currentTime {
          margin-top: 1vw;
          height: 30.7vw;
          border: dashed 1.5px rgba(255, 255, 255, 0.6);
          border-radius: 10px;
          min-width: 10vw;
          width: 100%;

          // &.hideBorder {
          //   border: none;
          // }

          &.showActive {
            border: solid 2px #4aacdd;

            // &.hideBorder {
            //   border: none;
            // }
          }

          .titleStyles {
            font-family: $InterBold;
            font-size: 3vw;
            font-weight: bold;
            color: var(--white);
            line-height: 1.2;
            margin-top: 0.8vw;
            margin-bottom: 0.36vw;
            width: 30vw;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }
          .bodyStyles {
            font-family: $InterRegular;
            font-size: 1.15vw;
            font-weight: normal;
            color: var(--white);
            line-height: 1.3;
            margin-top: 0.8vw;
            margin-bottom: 0.36vw;
            width: 30vw;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }
          .subTextStyles {
            font-family: $InterMedium;
            // font-size: 11.5px;
            font-size: 0.9vw;
            font-weight: 500;
            color: #32ac60;
            line-height: 1.3;
            margin-top: 0.8vw;
            margin-bottom: 0.36vw;
            width: 30vw;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }
          .preTitleStyles {
            margin-top: 0.28vw;
            font-size: 1.4vw;
            font-family: $InterBold;

            color: #32ac60;
            margin-right: 4px;
          }

          .eventBgImg {
            width: 46.87%;
            position: absolute;
            height: 30.4vw;
            // height: auto;
            // width: auto;
            border-radius: 8px;

            @media screen and (max-width: 1407px) and (min-width: 1300px) {
              width: 46.7%;
            }
            @media screen and (max-width: 1299px) and (min-width: 1199px) {
              width: 46.5%;
            }
            @media screen and (max-width: 1198px) and (min-width: 1099px) {
              width: 46.3%;
            }
            @media screen and (max-width: 1098px) and (min-width: 999px) {
              width: 46.1%;
            }
            @media screen and (max-width: 998px) and (min-width: 899px) {
              width: 45.9%;
            }
            @media screen and (max-width: 898px) and (min-width: 859px) {
              width: 45.7%;
            }
            @media screen and (max-width: 858px) and (min-width: 799px) {
              width: 45.5%;
            }
            @media screen and (max-width: 798px) and (min-width: 759px) {
              width: 45.3%;
            }
            @media screen and (max-width: 758px) and (min-width: 699px) {
              width: 45.1%;
            }
            // @media screen and (max-width: 434px) and (min-width: 300px) {
            //   width: 270%;
            // }

            // &.showBorder {
            //   border: solid 2px #4aacdd;
            // }

            object-fit: cover;
          }
        }
      }
      .sectionTwo {
        display: grid;
        padding-left: 0.7vw;
        width: 50%;
        grid-template-columns: 1fr;
        row-gap: 1vw;
        margin-bottom: 20px;
        .currentTime {
          border: dashed 1.5px rgba(255, 255, 255, 0.6);
          border-radius: 10px;
          height: 5.35vw;
          min-width: 10vw;

          .meetingContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .subjectInfo {
              display: flex;
              align-items: baseline;
              padding-top: 0.6vw;
              justify-content: space-between;

              .meetingInfoAndDetails {
                display: flex;
                align-items: baseline;
                .meetingInfo {
                  font-size: 19.8px;
                  font-size: 1.4vw;
                  font-family: $InterBold;

                  color: #1e6143;

                  &.ongoing {
                    color: #d8354b;
                  }
                }

                .roomDetails {
                  color: rgba(255, 255, 255, 0.65);
                  // font-size: 10.9px;
                  font-size: 0.8vw;
                  font-family: $InterRegular;
                }
              }

              .meetingTime {
                font-family: $InterBold;
                // font-size: 19.8px;
                font-size: 1.38vw;
                font-weight: 500;
                color: var(--white);
                line-height: 1;
                color: #eeefef;
                margin-bottom: 0.36vw;
                // margin-bottom: 5px;
              }
            }
            .description {
              font-family: $InterRegular;
              // font-size: 13.5px;
              font-size: 0.9vw;
              font-weight: 500;
              color: var(--white);
              line-height: 1;
              margin-bottom: 5px;
              margin-bottom: 0.36vw;
            }
            .orgainzerContainer {
              display: flex;
              justify-content: space-between;
              .organizer {
                font-family: $InterRegular;
                font-size: 10.9px;
                // font-size: 0.75vw;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.65);
                line-height: 1;
              }
              .subject {
                display: flex;
                .heading {
                  font-family: $InterRegular;
                  // font-size: 10.9px;
                  font-size: 0.8vw;
                  padding-left: 2px;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 0.65);
                  line-height: 1;
                }
              }
            }
          }

          .logoHolder {
            width: 30%;
            height: 100%;
            max-height: 70px;
            padding: 0px 0px 10px 0px;
            img {
              // width: 100%;
              height: 100%;
            }
          }
          &.showActive {
            border: solid 2px #4aacdd;
          }
          .time {
            font-family: $InterBold;
            // font-size: 30.2px;
            font-size: 2.08vw;
            font-weight: 500;
            color: var(--white);
            text-align: start;

            &.rightAligned {
              text-align: end;
            }
          }
          .date {
            font-family: $InterRegular;
            // font-size: 11.5px;
            font-size: 0.78vw;
            color: var(--white);
            text-align: start;

            &.rightAligned {
              text-align: end;
            }
          }
        }
      }
    }
  }
}
