@import '../../../sass/fontFamilies.scss';

.contentList {
  margin: 0 -24px;
  @media screen and (min-width: 800px) {
    max-height: 250px;
    margin: 0 -20px;
  }
  height: 54vh;
  overflow: auto;
  border-top: 1px solid var(--grey-6);

  .noPlaylistsFound {
    font-family: $InterMedium;
    // color: #fff;
    border: 1px solid #d8dae9;
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    margin: 10px 0;
    &.desktop {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    &.responsive {
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
  }
  .content {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-6);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    cursor: pointer;
    &:hover {
      background: var(--screenz-main-hover);
    }
    &.active {
      background: var(--screenz-main-hover);
      border: 1px solid #615ae2;
    }
    .checkbox {
      margin-right: 8px;
    }
    .previewWrapper {
      width: 80px;
      height: 45px;
      margin-right: 5px;
      margin-left: 1rem;
      background: var(--grey-7);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
      .videoPlayerIcon {
        position: absolute;
        width: 24px;
        height: 24px;
      }
    }
    .contentNameAndLength {
      > p,
      > span {
        font-family: $InterRegular;
        font-size: 13px;
        color: #33373a;
      }
      span {
        color: #7f8fa4;
      }
    }
  }
}
