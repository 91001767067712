@import '../../../../../sass/fontFamilies.scss';

.clientsDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #feffff;
  border: 1px solid #aaadc4;
  color: #aaadc4;
  border-radius: 8px;
  padding: 10px;

  &:focus {
    outline: none;
    border-color: #615ae2;
  }
  @media screen and (min-width: 800px) {
    width: 243px;
    // margin: 10px;
  }
  // &.shown {
  //   background: #3b369a;
  // }
  .clientName {
    // color: #fffcef;
    font-size: 14px;
    // width: 24px;

    font-family: $InterRegular;
  }
  .expandArrowDown {
    margin: 0 0 0 5px;
    width: 8px;
    height: 8px;
    transition: 400ms;
  }
  .shown {
    transform: rotate(90deg);
  }
}
.dropdown {
  border-radius: 8px;
  width: 95%;
  left: 10px;
  @media screen and (min-width: 800px) {
    min-width: 243px;
    width: 243px;
  }
  .searchContainer {
    border-bottom: 1px solid #acaec5;
    padding: 10px;
  }
  .listContainer {
    max-height: 350px;
    overflow: auto;
    .listItem {
      padding: 10px;
      cursor: pointer;
      font-size: 14px;
      color: #111216;
      font-family: $InterRegular;
      list-style-type: none;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 400ms;
      &:hover {
        color: #615ae2;
        background: #f5f5fd;
      }
      &.active {
        color: #615ae2;
        background: #f5f5fd;
        font-weight: 500;
        font-family: $InterMedium;
      }
      .activeClientIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
