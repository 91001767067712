@import '../../sass/fontFamilies.scss';

.componentContainer {
  padding-top: 15px;
  // padding-bottom: 10px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  // align-items: center;
  flex-direction: column;
  // justify-content: space-between;

  @media screen and (max-width: 800px) {
    display: none;
  }

  .searchContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    .searchField {
      width: 20%;
    }
    padding: 0 20px;
  }
  .listContainer {
    border: var(--border);
    background: var(--white);
    width: 100%;
    // overflow-y: scroll;
    overflow: hidden;
    // border-radius: 10px;
    border-left: none;
    margin: 15px 0px 0 0;

    .clientListContainer {
      width: 100.22%;
      height: 89.5%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .listHeader {
      grid-template-columns: 20% 0% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1%;
      border-bottom: var(--border);
      padding: 0 20px;
      // @media screen and (min-width: 800px) {
      display: grid;
      // }
      .nameContainer {
        border-right: 1px solid var(--grey-6);
        display: flex;
        padding: none;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;
        .name {
          font-family: $TTInterphasesMedium;
          color: var(--grey-1);
          background-color: white;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          display: flex;
          padding: none;
          justify-content: center;
          align-items: center;
        }
        .type {
          font-family: $TTInterphasesMedium;
          color: #7f8fa4;
          background-color: white;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          display: flex;
          padding: none;
          justify-content: center;
          align-items: center;
        }
      }
      .checkBoxContanier {
        height: 58px;
        font-family: $TTInterphasesMedium;
        color: #7f8fa4;
        background-color: var(--grey-7);
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-right: 1px solid var(--grey-6);
      }
      .horizontalLine {
        box-shadow: 1px 0px 4px 0.5px var(--grey-1);
        // position: absolute;
        z-index: 100;
      }

      &.firstHeader {
        p {
          height: 58px;
          font-family: $TTInterphasesMedium;
          color: #7f8fa4;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          border-right: 1px solid var(--grey-6);
          background-color: white;
        }
        p:nth-child(1) {
          display: flex;
          background-color: white;
          justify-content: start;
          text-align: start;
          padding-left: 1rem;
          // padding-top: -1rem;
        }
      }
    }
  }
}
