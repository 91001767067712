@import '../../sass/fontFamilies.scss';

.o365ModalContainer {
  max-width: 505px;
  .O365Modal {
    @media screen and (min-width: 800px) {
      min-width: 460px;
      margin-bottom: 1.5rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .o365Btn {
      background-color: #176bf6;
      color: white;
      border: none;
      height: 50px;

      &:hover {
        background-color: #0053d8;
        //   flex-grow: 1;
        color: white;
      }
    }
  }

  .notes {
    font-family: $InterRegular;
    color: #33373a;
    font-size: 13px;
    margin-top: 4rem;
  }

  .notesContent {
    font-family: $InterRegular;
    color: var(--grey-4);
    font-size: 11px;
    margin-top: 0.5rem;
  }
}
