@import '../../sass/fontFamilies.scss';

.clientName {
  font-size: 15px;
  font-weight: 500;
  font-family: $InterMedium;
  @media screen and (max-width: 800px) {
    display: none;
  }
  span {
    cursor: pointer;
    color: #6e6f72;
    &.active {
      color: #111216;
    }
  }
  .separator {
    margin: 0 3px 0 5px;
  }
}

.folderName {
  padding: 8px 10px;
  background: var(--white);
  border-bottom: var(--border);
  display: flex;
  align-items: center;
  font-size: 14px;
  @media screen and (min-width: 800px) {
    display: none;
  }
  p {
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-1);
  }
  @media screen and (min-width: 800px) {
    display: none !important;
  }
}
