@import '../../sass/fontFamilies.scss';

.sideDrawer {
  position: absolute;
  transition: 400ms;
  right: -100vw;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: var(--white);

  &.open {
    right: 0;
  }
  @media screen and (min-width: 800px) {
    right: 0;
    right: -100vw;
    // width: 35vw;
    // max-width: 550px;
    width: 600px;
    top: 72px;
    height: calc(100vh - 72px);
    border-radius: 8px 0 0 0;
    box-shadow: -4px 4px 4px 0 rgba(42, 44, 58, 0.15);
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--grey-6);
    border-top: 1px solid var(--grey-6);
    &.superAdmin {
      top: 135px;
      height: calc(100vh - 135px);
    }
    &.playlistDetails {
      top: 135px;
      height: calc(100vh - 72px);
    }
  }
}
