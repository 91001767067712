.switch {
  width: 38px;
  height: 20px;
  border-radius: 83px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 400ms;
  background: var(--grey-5);
  &.on {
    justify-content: flex-end;
    background: var(--screenz-main);
  }
  .button {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    border-radius: 83px;
    background: #fff;
    margin-left: 2px;
    margin-right: 2px;
  }
}
