@import '../../../sass/fontFamilies.scss';

.container {
  cursor: pointer;
  border: 1px solid #dfe2e5;
  background: #fff;
  border-radius: 8px;
  padding: 13px;
  display: flex;
  align-items: center;
  font-family: $InterRegular;
  font-size: 13px;
  color: var(--grey-2);
  &.small {
    padding: 8px;
    height: 48px;
  }
  &:hover {
    background: var(--screenz-main-hover);
    border: 1px solid #615ae2;
  }
  .folderIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
