@import '../../../sass/fontFamilies.scss';

.roleDetailsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  .rolesDetailsMainContainer {
    border: 1px solid red;
    height: 100%;
    overflow: auto;
    flex-grow: 1;
    background: #fff;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    border: var(--border);
    padding: 15px 30px;
    padding-bottom: 50px;
    &::-webkit-scrollbar {
      width: 0;
    }
    .permissionsContainer {
      .mainHeading {
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: var(--grey-1);
        padding: 15px 0;
        border-bottom: var(--border);
        &.noBorder {
          border: 0;
        }
      }
      .permissionMode {
        display: flex;
        align-items: center;
        height: 50px;
        padding-left: 10px;
        .radioBtn {
          margin-right: 5px;
        }
        .feature {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          color: var(--grey-1);
        }
      }
      .featuresAndAccess {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        padding-left: 10px;
        border-bottom: var(--border);
        .feature {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          color: var(--grey-1);
        }
      }
    }
  }
}
