:root {
  --grey-1: #111216;
  --grey-2: #2a2c3a;
  --grey-3: #5b5d70;
  --grey-4: #8a8daa;
  --grey-5: #acaec5;
  --grey-6: #d8dae9;
  --grey-7: #f0f3f8;
  --bluey-grey: #8a8daa;
  --grey-bg: #f4f5f7;
  --white: #fff;
  --screenz-main: #615ae2;
  --screenz-main-2: #9f77ed;

  --screenz-light-2: #615ae233;
  --screenz-light-3: #dbd3ed;
  --screenz-main-hover: #f5f5fd;
  --success: #36af47;
  --warning: #f7981c;
  --fail: #e94c5c;
  --border: 1px solid var(--grey-6);
  --base-color: #e9eaf1;
}
