@import '../../sass/fontFamilies.scss';

%orientationBtn {
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  width: 100%;
  .radioBtn {
    padding: 16px 12px;
    border: solid 2px var(--grey-6);
    border-radius: 8px 0 0 8px;
    height: inherit;
    background: var(--grey-bg);
    &.active {
      border: solid 2px var(--screenz-main);
      background: var(--screenz-main-hover);
    }
  }
  .screenType {
    padding: 10px 12px;
    // width: 16vh;
    border: solid 2px var(--grey-6);
    border-left: 0;
    border-radius: 0 8px 8px 0;
    height: inherit;
    display: flex;
    align-items: center;
    flex-grow: 1;
    .customHeading {
      display: flex;
      align-items: center;
      &.active {
        .screenIcon {
          margin-right: 5px;
          path {
            fill: var(--screenz-main);
          }
        }
      }
    }
    &.customResolution {
      display: flex;
      justify-content: space-between;
    }
    .screenIcon {
      margin-right: 5px;
      path {
        fill: var(--grey-4);
      }
    }
    .orientationType {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-4);
    }
    &.active {
      border: solid 2px var(--screenz-main);
      border-left: 0;
      background: var(--screenz-main-hover);
      .orientationType {
        color: #354052;
      }
      .screenIcon {
        margin-right: 5px;
        path {
          fill: var(--screenz-main);
        }
      }
    }
  }
}

.sizeAndOrientation {
  .btnContainer {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    .orientationBtn {
      @extend %orientationBtn;
      margin-bottom: 20px;
    }
  }
  .orientationBtn {
    @extend %orientationBtn;
    &.active {
      background: var(--screenz-main-hover);
    }
    .resolutionContainer {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      .widthContainer,
      .heightContainer {
        display: flex;
        align-items: center;
        .heighWithLabel {
          font-family: $InterRegular;
          font-size: 13px;
          line-height: 1.15;
          letter-spacing: 0.2px;
          margin-right: 8px;
          color: var(--grey-6);
          @media screen and (max-width: 800px) {
            visibility: hidden;
            width: 11px;

            &:first-letter {
              visibility: visible;
            }
          }
          ​ &.active {
            color: var(--grey-1);
          }
        }
        .resolutionInput {
          width: 58px;
          padding: 8px;
          border-radius: 6px;
          border: solid 1px var(--grey-6);
          text-align: center;
          &.active {
            border: solid 1px var(--screenz-main);
          }
          &:disabled {
            background: transparent;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .deviceTypes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 68px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 6px;
        border: solid 1px var(--grey-6);
        background-color: var(--grey-7);
        position: relative;
        cursor: pointer;
        .screen {
          width: 18px;
          height: 18px;
          left: 10%;
          position: absolute;
          path {
            fill: var(--grey-5);
          }
          &.active {
            path {
              fill: #fff;
            }
          }
        }
        .portrait {
          right: 10%;
          position: absolute;
          path {
            fill: var(--grey-5);
          }
          &.active {
            path {
              fill: #fff;
            }
          }
        }
        .activeIndicator {
          width: 50%;
          height: 30px;
          cursor: pointer;
          background-color: var(--screenz-main);
          position: absolute;
          &.landscape {
            left: 0;
            border-radius: 6px 0 0 6px;
          }
          &.portrait {
            right: 0;
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
  }
}
