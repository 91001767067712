@import '../../../sass/fontFamilies.scss';

.runningStatus {
  padding: 8px 6px 6px 6px;
  border-radius: 3px;
  font-family: $TTInterphasesMedium;
  font-size: 10px;
  white-space: nowrap;
  background: #acaec5;
  color: #fff;
  width: fit-content;
  &.inUse {
    background: var(--success);
    color: var(--white);
  }
}
