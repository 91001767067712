@import '../../../sass/fontFamilies.scss';

.screen {
  border-bottom: 0px;
  border-radius: 10px 0 0 0;
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d8dae9;
  /* margin: 0 0 0 0; */
  border-bottom: 0;
  width: 147px;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  // &.noScreenShot {
  //   padding: 10px;
  // }

  .screenBeingAddLoder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loadingCircle {
      width: 40px;
      margin-bottom: 10px;
      animation: rotate 2s linear infinite;
      @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }
    }
    p {
      width: 80%;
      text-align: center;
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.15;
      letter-spacing: 0.2px;
      color: #7f8fa4;
    }
  }

  @media screen and (min-width: 800px) {
    width: auto;
    border-radius: 10px 10px 0 0;
    min-height: 175px;
    flex-basis: 40%;
  }
  .screenshot {
    width: 100%;
    border-radius: 10px 0 0 0;
    height: 83px;
    object-fit: cover;
    @media screen and (min-width: 800px) {
      border-radius: 10px 10px 0 0;
      min-height: 175px;
    }
  }
  .connectionStatus {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    font-family: $InterMedium;
    color: #5b5d70;
    max-width: 145px;
    // text-align: center;
    .svg {
      margin-bottom: 5px;
      width: 41px;
      height: 41px;
      @media screen and (max-width: 800px) {
        max-width: 25px;
        width: 7vw;
        height: auto;
      }
      path {
        fill: #5b5d70;
      }
    }
  }

  .Conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .sharedStatus {
    padding: 4px 9px;
    font-family: $InterMedium;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    position: absolute;
    margin-left: 12rem;
    margin-top: -8rem;
    font-style: normal;
    z-index: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
    background-color: var(--success);
  }
}
