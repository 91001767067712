@import '../../../sass/fontFamilies.scss';

.tableHead {
  border: 1px solid var(--grey-6);
  border-radius: 10px !important;
  width: 100%;
  // margin-bottom: 5px;
  background: var(--white);
  padding-bottom: 5px;

  @media screen and (max-width: 800px) {
    display: none;
  }
  &:hover {
    border-radius: 10px !important;
  }
  thead {
    tr {
      grid-template-columns: 48% 20% 20% 10% 2%;
      display: grid;
      padding: 15px 40px;
      border-bottom: 1px solid var(--grey-6);
      th {
        text-align: left;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-family: $InterRegular;
        font-size: 14px;
        font-weight: 500;
        color: #7f8fa4;
        &.hideSort {
          display: none;
        }
        .timerIcon {
          margin-right: 3px;
        }
        .sortIcon {
          margin-left: 8px;
        }
      }
    }
  }
  tbody {
    display: block;
    overflow: auto;
    max-height: 400px;

    height: 100vh;
    tr {
      display: grid;
      padding: 15px 40px;
      border-bottom: 1px solid var(--grey-6);
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      cursor: pointer;
      transition: 200ms;
      align-items: center;
      &.active {
        background: var(--screenz-main-hover);
        border: 1px solid #615ae2;
      }

      &:hover {
        background: var(--screenz-main-hover);
      }
      &.gridFiveColumns {
        grid-template-columns: 5% 45% 20% 20% 10%;
      }
      &.gridFourColumns {
        grid-template-columns: 48% 20% 20% 10% 2%;
      }
      td {
        display: flex;
        align-items: center;
        color: var(--bluey-grey);
        font-family: $InterRegular;
        white-space: nowrap;
        // overflow: hidden;
        // overflow: a;
        text-overflow: ellipsis;
        &.name {
          color: #2a2c3b;
        }

        .sharedStatus {
          font-family: $InterMedium;
          font-size: 11px;
          padding: 3px 5px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          border-radius: 5px;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-left: 0.5rem;
          color: var(--white);
          background-color: var(--success);
        }
        .playlistsIcon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        .screenIcon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          path {
            fill: var(--bluey-grey);
          }
        }
        .morePopupContainer {
          // position: absolute;
          .popover {
            right: 0;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
            &:hover {
              background: var(--screenz-main-hover);
            }
            &.last {
              top: -10px;
              right: 25px;
            }
          }
          .morePopup {
            right: 0;
            display: flex;
            align-items: center;
            padding: 10px;
            font-family: $InterRegular;
            font-size: 14px;
            line-height: 1.36;
            color: var(--grey-1);
            img {
              width: 24px;
              height: 24px;
              margin-right: 5px;
            }

            &.disabled {
              background-color: rgb(239, 239, 239);
              opacity: 0.4;
            }
          }
          .moreBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 300ms;
            .icon {
              path {
                fill: var(--grey-4);
              }
            }
            &:hover {
              background: var(--grey-6);
              border-radius: 5px;
              .icon {
                path {
                  fill: var(--grey-3);
                }
              }
            }
          }
        }
      }
    }

    // &:last-child {
    //   padding-bottom: 1rem;
    //   // background-color: black;
    // }
  }
}
