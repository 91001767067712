@import '../../../../../sass/fontFamilies.scss';
.overLay {
  .checkBoxContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2vh;
    .checkBoxTitle {
      font-family: $InterMedium;
      font-size: 14px;
      text-align: left;
      color: var(--grey-1);
      margin-left: 0.5vw;
      font-weight: 500;
      line-height: 1.4;
      cursor: pointer;
    }
  }
  .sliderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .slider {
      width: 100vw;
      // appearance: none;
      background: #d8dae9;
      height: 4px;
      border-radius: 4px;
      outline: none;
      padding: 0;
      margin: 10px 0;
      accent-color: rgb(97, 90, 226);
      cursor: pointer;
      &.focus {
        outline: none;
      }
    }
    .slider::-webkit-slider-thumb {
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #615ae2;
      cursor: pointer;
      transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    .slider::-moz-range-thumb {
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #615ae2;
      cursor: pointer;
      box-shadow: 0px 0px 0px 4px #d0cdff;
      transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    .slider:hover::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px 4px #d0cdff;
    }

    .slider:hover::-moz-range-thumb {
      box-shadow: 0px 0px 0px 4px #d0cdff;
    }
    .progressInputField {
      width: 44px;
      height: 30px;
      background-color: inherit;
      margin-left: 2vh;
    }
  }
}
