@import '../../../../sass/fontFamilies.scss';

.subheader {
  background: var(--screenz-main);
  padding: 23px 20px;
  .heading {
    font-family: $InterMedium;
    font-size: 21px;
    font-weight: 500;
    color: var(--white);
  }
}
