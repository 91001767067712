@import '../../../../../sass/fontFamilies.scss';
.listAndDetails {
  height: 100%;
  overflow: hidden;
  @media screen and (min-width: 800px) {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;

    &.displayboth {
      grid-template-columns: 1fr 340px;
    }
  }
  .foldersAndScreensWrapper {
    height: auto;
    @media screen and (max-width: 800px) {
      height: calc(100% - 89px);
    }
    &.noData {
      @media screen and (max-width: 800px) {
        height: 130%;
      }
    }
    overflow: auto;
    display: flex;
    flex-direction: column;
    .screensSkeleton {
      margin: 20px;
    }
    .foldersWrapper {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      padding: 15px 20px 10px 20px;
    }
    .screenList {
      padding: 2px;
      flex-grow: 1;
      display: grid;
      margin-bottom: 7rem;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      grid-auto-rows: minmax(min-content, max-content);
      &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
      }
      @media screen and (min-width: 800px) {
        // display: none;
        padding: 15px 20px 20px 20px;
      }
    }

    .screenHeader {
      padding: 0 0 0 0px;
      margin: 0 31px 31px;
      font-family: 'Inter-Regular';
      position: relative;
      top: 38px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.32px;
      text-align: left;
      color: var(--grey-1);
    }
    .gridContainer {
      padding: 0 10px;
      // display: none;

      .heading {
        font-family: $InterMedium;
        margin: 10px 0 10px 20px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.32px;
        text-align: left;
        color: var(--grey-1);
      }

      @media screen and (min-width: 800px) {
        display: block;
      }
      > div {
        // display: grid;
        // display: grid !important;
        // grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        // grid-template-columns: 1fr 1fr 1fr 1fr;
        // grid-auto-rows: minmax(min-content, max-content);
        // overflow-x: hidden;
        // grid-gap: 10px;
        // flex-wrap: wrap;
        &:last-child {
          // width: 67%;

          // > div {
          //   max-width: 350px;
          // }
          > div {
            @media screen and (min-width: 800px) {
              max-width: 350px;
            }
          }
        }

        > div {
          // max-width: 270px;
          // min-width: 300px;
          min-width: 270px;
          width: 100%;
          // max-width: 350px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
      &.Screens {
        display: flex;
      }
    }
    .lastPingAndRefresh {
      padding: 15px;
    }
  }
  .detailView {
    // height: 100%;
    // margin-top: -1.5px;
    height: 101%;
    margin-top: -1px;
    @media screen and (max-width: 800px) {
      display: none;
      &.ScreensDetails {
        display: flex;
        height: 110%;
      }
    }
  }
}
