html,
body {
  box-sizing: border-box;
  height: 100%;
}
@media screen and (max-width: 800px) {
  body {
    zoom: 1;
    -webkit-overflow-scrolling: touch;
    /* touch-action: none; */
  }
}
::-webkit-scrollbar {
  width: 3px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: rgb(233, 233, 233); /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  /* background-color: blue; color of the scroll thumb */
  border-radius: 50px; /* roundness of the scroll thumb */
  border: 3px solid rgb(155, 155, 155); /* creates padding around scroll thumb */
}

#root {
  height: 100%;
}
#root > .App {
  height: 100%;
  min-width: 250px;
  /* overflow: hidden; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-drag: none;
}

button {
  user-select: none;
  outline: none;
}

a {
  outline: none;
  text-decoration: none;
}
.activeContent {
  border: 3px solid blue;
  background: var(--screenz-main-hover);
}
@media only screen and (max-width: 800px) {
  .intercom-lightweight-app {
    position: fixed;
    z-index: 2147483001;
    width: 0;
    height: 0;
    left: 1rem !important;
    font-family: intercom-font, 'Helvetica Neue', 'Apple Color Emoji', Helvetica,
      Arial, sans-serif;
    border: 1px solid red;
    display: none;
  }
}

table {
  border-spacing: 0 !important;
}

hr {
  margin-right: -10px;
  background-color: var(--grey-6);
  border: 0;
  height: 0.5px;
}

.toasterStyles {
  font-family: 'Inter-Regular';
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.react-loading-skeleton {
  z-index: 0;
}
