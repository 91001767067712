@import '../../../../../sass/fontFamilies.scss';

.screenDetailsSubHeader {
  padding: 10px 20px;
  border-bottom: 1px solid var(--grey-6);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 800px) {
    background: var(--white);
    height: 50px;
  }

  .colOne {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    @media screen and (max-width: 799px) {
      flex-grow: 1;
    }
    .changeViewBtn {
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
    .clockIcon {
      width: 24px;
      height: 24px;
      @media screen and (max-width: 799px) {
        margin-left: auto;
      }
    }
    .playLength,
    .duration {
      font-family: $InterRegular;
      font-size: 13px;
    }
    .playLength {
      color: #7f8fa4;
      @media screen and (max-width: 799px) {
        display: none;
      }
    }
    .duration {
      color: var(--grey-1);
    }
  }
  .colTwo {
    position: relative;
    .addContentBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      transition: 400px;
      @media screen and (max-width: 799px) {
        position: absolute;
        right: -10px;
        top: 40px;
        z-index: 2;
      }
      &.PlaylistDetails {
        display: none;
      }
    }
  }
}
