@import '../../../../../sass/fontFamilies.scss';

.screenzDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0;
  @media screen and (max-width: 800px) {
    overflow: auto;
    padding-bottom: 120px;
  }
  @media screen and (min-width: 800px) {
    border: var(--border);
    background: #fff;
    min-width: 312px;
    border-right: none;
  }
  .headingSection {
    width: 100%;
    padding: 8px;
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: var(--border);
    // border-top: 0;
    display: none;
    > button {
      background: #dfdef9;
      color: #615ae2;
      padding: 10px;
      border: 0;
      border-radius: 8px;
      font-family: $InterMedium;
      cursor: pointer;
    }
    @media screen and (min-width: 800px) {
      display: block;
    }
  }

  .primaryDetails {
    // height: calc(100% - 110px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;

    @media screen and (max-width: 800px) {
      height: 100%;
    }
    .folderIcon {
      margin-top: 45px;
      margin-bottom: 5px;
    }
    .paragraphOne {
      margin-bottom: 12px;
      max-width: 186px;
      font-size: 21px;
      text-align: center;
      font-family: $InterRegular;
      color: #111216;
    }
    .paragraphTwo {
      margin-bottom: 15px;
      max-width: 186px;
      font-size: 18px;
      text-align: center;
      font-family: $InterRegular;
      color: #5b5d70;
    }
    .container {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .connectionStatus {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        .statusIcon {
          height: 40px;
          width: 40px;
          border-radius: 100%;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.connected {
            background: #36af47;
          }
          &.disconnected {
            background: #acaec5;
          }
        }

        .count {
          font-size: 46px;
          font-weight: 500;
          font-family: $InterMedium;
          color: #000000;
        }
        .message {
          font-size: 15px;
          font-family: $InterRegular;
          color: #5b5d70;
        }
      }
    }
    .screenFolderShare {
      width: 100%;
      padding: 20px 10px;
    }
  }
}
