@import '../../../../../../sass/fontFamilies.scss';

.templateSettingsBar {
  background: transparent;
  min-width: 280px;
  width: 280px;
  border: var(--border);
  border-radius: 10px 0 0 0;
  border-right: 0;
  border-top: 0;
  padding: 0 15px;
  background: #fcfcfd;

  .hr {
    margin: 0 -15px;
    height: 1px;
  }

  .hrTwo {
    margin-right: -15px;
    height: 1px;
  }

  .headingContainer {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    border-radius: 10px 0 0 0;
    margin: 0 -15px;
    .templatesIcon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      path {
        fill: var(--grey-4);
      }
    }
    .icon {
      margin-right: 8px;
    }
    .heading {
      font-family: $InterRegular;
      font-size: 14px;
      color: var(--grey-1);
    }
    .closeIcon {
      margin-left: auto;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .toggleSettingsContainer {
    padding: 15px 0;
    .heading {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
      margin-bottom: 20px;
    }
  }
}
