@import '../../../../sass/fontFamilies.scss';

.accordion {
  .accordionHeader {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    user-select: none;
    // border-bottom: var(--border);
    .folderIconAndName {
      display: flex;
      align-items: center;
      margin-left: 5px;
      .folderIcon {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
      .folderName {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
      }
    }
    .checkbox {
      margin-left: auto;
    }
    .dropdownIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transition: 300ms;
        &.opened {
          transform: rotate(90deg);
        }
      }
    }
  }
  .dropdownContainer {
    max-height: 0;
    overflow: hidden;
    transition: 500ms;
    &.opened {
      transition: 500ms;
      max-height: 500px;
    }
  }
}
