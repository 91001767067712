@import '../../../../../sass/fontFamilies.scss';

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 6px;
  &.show {
    background: #3b369a;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
  .profilePic {
    margin-right: 8px;
  }
  .username {
    font-size: 13px;
    font-family: $InterRegular;
    color: #c8c7e8;
  }
  .expandArrowDown {
    margin: 0 5px;
    transform: rotate(90deg);
  }
}

.logoutPopover {
  border-radius: 8px;
  min-width: 180px;
  right: 22px;
  // padding: 13px;
  // cursor: pointer;
  .logout {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    padding: 13px 15px;
    // margin-bottom: 0.8rem;
    font-family: $InterRegular;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    &:hover {
      background: var(--screenz-main-hover);
      border-radius: 8px;
      // margin-
    }

    &:last-child {
      &:hover {
        // background: var(--screenz-main-hover);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:first-child {
      &:hover {
        // background: var(--screenz-main-hover);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
