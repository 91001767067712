@import '../../sass/fontFamilies.scss';

.addFolderModal {
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
    width: 100vw;
  }
  .labelAndInput {
    margin-bottom: 10px;
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: center;
    }
    margin-bottom: 15px;
    .label {
      font-size: 13px;
      font-family: $InterRegular;
      color: #33373a;
      display: inline-block;
      width: 102px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      flex-grow: 1;
      font-size: 14px;
      font-family: $InterRegular;
      background: transparent;
      color: #33373a;
      @media screen and (min-width: 800px) {
        width: auto;
      }
    }
  }
  .addModalHrLine {
    margin: 0 -22px;
    background-color: var(--grey-6);
    height: 1px;
    border: 0;
  }
  .orientationHeading {
    display: flex;
    align-items: center;
    padding: 12px 0;
    .orintationIcon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .orintation {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.3px;
      color: var(--grey-1);
    }
  }
}
