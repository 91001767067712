@import '../../../../../../../sass/fontFamilies.scss';

.nameContainer {
  padding: 15px 0;
  .label {
    margin-bottom: 10px;
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  .customNameInput {
    border: solid 1px var(--grey-5);
    background-color: var(--white);
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-1);

    &.extraWidth {
      height: 52px;
    }

    &.addExtraBodyWidth {
      height: 140px;
    }
  }

  .textAreaContent {
    border: 1px solid #aaadc4;
    color: #111216 !important;
    border-radius: 5px;
    padding: 8px 10px;
    width: 100%;
    &.hideBorder {
      border: none;
    }
    &::after {
      content: 'E';
      right: -20px;
      height: 10px;
      width: 10px;
      background: red;
    }
    &:focus {
      outline: none;
      border-color: #615ae2;
      color: #111216 !important;
    }
    &.disabled {
      background: #d9e8f7;
    }
    &.error {
      border: 1px solid var(--fail);
      background: #fef1f2 !important;
    }

    border: solid 1px var(--grey-5);
    background-color: var(--white);
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-1);
    resize: none;

    &.extraWidth {
      height: 68px;
    }

    &.addExtraBodyWidth {
      height: 160px;
    }
  }
}
