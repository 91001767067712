@import '../../../../../../../sass/fontFamilies.scss';

.toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .label {
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.2px;
    color: var(--grey-1);
  }
}
