@import '../../../../sass/fontFamilies.scss';

.playlistDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0;
  @media screen and (min-width: 800px) {
    border: var(--border);
    border-bottom: none;
    background: #fff;
    min-width: 312px;
    border-right: none;
  }
  .headingSection {
    width: 100%;
    padding: 8px;
    border-top: 0;
    border-bottom: var(--border);
    display: none;
    > button {
      background: #dfdef9;
      color: #615ae2;
      padding: 10px;
      border: 0;
      border-radius: 8px;
      font-family: $InterMedium;
      cursor: pointer;
    }
    @media screen and (min-width: 800px) {
      display: block;
    }
  }
  .folderIcon {
    margin-top: 45px;
    margin-bottom: 5px;
  }
  .paragraphOne {
    margin-bottom: 25px;
    max-width: 186px;
    font-size: 21px;
    text-align: center;
    font-family: $InterRegular;
    color: #111216;
  }
  .sharePlaylist {
    width: 100%;
    padding: 15px;
  }
}
