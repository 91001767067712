@import '../../../../../../../sass/fontFamilies.scss';

.screenshotWrapper {
  padding: 20px 20px 57px 20px;
  border-radius: 10px;
  border: 1px solid var(--grey-6);
  background: var(--white);
  // margin-bottom: 100px;
  .nameAndTime {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    img {
      margin-right: 5px;
    }
    p:nth-child(2) {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: var(--grey-1);
    }
    p:nth-child(3) {
      margin-left: auto;
      font-family: $InterRegular;
      font-size: 14px;
      color: var(--grey-3);
    }
  }
  .screenshot {
    width: 100%;
    border-radius: 8px;
  }
}
