@import '../../../../../../../sass/fontFamilies.scss';

.contentTypeDropdown {
  padding: 15px 0;
  .sectionHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
    .sectionHeading {
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.15;
      letter-spacing: 0.2px;
      color: var(--grey-1);
    }
    .msIcon {
      width: 102px;
      height: 23px;
    }
  }

  .dropdownChoosedOption {
    display: flex;
    align-items: center;
    border: solid 1px var(--grey-5);
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    .icon {
      width: 24px;
      height: 22px;
      margin-right: 5px;
    }
    .option {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .dropdownIcon {
      width: 11px;
      margin-left: auto;
    }
  }
  .popoverClassName {
    width: 100%;
    border-radius: 8px;
    .viewOptions {
      width: 100%;
      list-style-type: none;
      max-height: 300px;
      overflow: auto;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      & :last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .option {
        padding: 10px;
        color: var(--grey-1);
        font-size: 14px;
        font-family: $InterRegular;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 29px;
          margin-right: 10px;
        }
        &:hover {
          background: var(--screenz-main-hover);
        }
      }
    }
  }
}
