@import '../../../../../../../sass/fontFamilies.scss';

.assetManager {
  padding: 15px 0;
  .headingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    cursor: pointer;
    .heading {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .arrorIcon {
      transform: rotate(90deg);
      width: 20px;
    }
  }
  .hr {
    margin-right: -15px;
    height: 1px;
  }
  .previewAndChangeBtn {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    margin-top: 15px;
    .previewContainer {
      width: 9.5vw;
      min-height: 45px;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #bababa;
      &.bgPreview {
        height: 66px;
      }
      &.logo {
        padding: 10px;
        border-radius: 8px;
      }
      .preview {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .fileUploadBtnContainer {
      position: relative;
      cursor: pointer;
      .changeImgBtn {
        padding: 5px;
        border-radius: 8px;
        font-family: $InterMedium;
        font-size: 11px;
        font-weight: 500;
        color: var(--grey-1);
        width: 96px;
        height: 28px;
        border: var(--grey-7);
        background-color: var(--grey-7);
        margin-bottom: 1vh;
      }
      .fileInput {
        position: absolute;
        top: 0;
        font-size: 20px;
        opacity: 0;
        width: 100%;
      }
    }
  }
}
