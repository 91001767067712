@import '../../sass/fontFamilies.scss';

.o365ModalContainer {
  max-width: 860px;
  .mainHeading {
    font-family: $InterRegular;
    color: #000000;
    font-size: 14px;
    margin-top: 0.5rem;
  }

  .tableContainer {
    border: 1px solid var(--grey-6);
    border-radius: 8px;
    min-width: 820px;
    margin: 1.5rem 0 1rem;
    .tableHead {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--grey-6);
      padding: 10px 10px 15px;
      // margin-bottom: 1rem;
      .headerTabs {
        width: 33%;
        font-family: $InterMedium;
        color: #7f8fa4;
        font-size: 14px;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        .name {
          margin-left: 0.5rem;
        }

        &.secondColumn {
          padding-left: -5px;
        }

        &.thirdColumn {
          padding-left: 2.5rem;
        }
      }
    }
    .notFoundTitle {
      width: 100%;
      height: 10vh;
      padding: 4vh;
      text-align: center;
      font-family: $InterMedium;
      color: #111216;
      font-size: 16px;
      font-weight: 500;
    }
    .tableBody {
      margin-bottom: 1rem;
      max-height: 15rem;
      overflow-y: auto;
      .bodyContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--grey-6);
        padding-bottom: 8px;
        // padding-left: 5px;

        .bodyColumns {
          width: 30%;
          font-family: $InterMedium;
          color: var(--grey-2);
          font-size: 14px;
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          .name {
            margin-left: 0.5rem;
          }

          &.secondColumn {
            width: 40%;
          }

          &.thirdColumn {
            width: 27%;
          }
        }
      }
    }
  }
}
