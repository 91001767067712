.calenderEvents {
  display: flex;
  flex-direction: column;
  height: 100%;
  .mainSection {
    flex-grow: 1;
    display: flex;
    height: 100%;
    background: transparent;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0; /* width of the entire scrollbar */
    }
    // &.hideScroll {
    //   overflow-y: hidden;
    // }
  }
}
