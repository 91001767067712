@import '../../sass/fontFamilies.scss';

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
  color: #9f77ed;
  font-family: 'Inter-SemiBold';
  .loaderText p {
    margin-bottom: 5rem;
  }
  .delayMessage {
    text-align: center;
    font-size: 16px;
    font-family: $InterRegular;
    color: #33373a;
    display: inline-block;
    padding: 15px 0;
  }
}
