@import '../../../../../sass/fontFamilies.scss';

.calenderEventsHeader {
  border: 1px solid red;
  background: #fff;
  height: 50px;
  border: var(--border);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  .leftOne,
  .rightOne {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .leftOne {
    .backIcon {
      margin-right: 15px;
      cursor: pointer;
    }
    .dropdownHeading {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
      margin-right: 15px;
      cursor: pointer;
    }
    .dropdownIcon {
      transform: rotate(-90deg);
      width: 16px;
      cursor: pointer;
    }
  }
  .rightOne {
    display: flex;
    justify-content: flex-end;
    .saveTemplateBtn {
      height: 27px;
      padding: 10px;
      font-size: 11px;
      font-weight: 500;
      color: var(--white);
    }
  }
}
