@import '../../../../../sass/fontFamilies.scss';
.timePickers {
  align-items: center;
  grid-gap: 5px;
  display: none;

  @media screen and (min-width: 800px) {
    display: flex;
    margin-left: -11rem;
  }
  .videoPreview {
    width: 0px;
  }
  .timePicker {
    font-family: $InterRegular;
    font-size: 13px;
    color: var(--bluey-grey);
    width: 58.7px;
    height: 36px;
    // border: 1px solid var(--grey-5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #fff;
    .dropdownIcon {
      width: 10px;
      margin-left: 10px;
    }
    &.opened {
      border: 1px solid var(--screenz-main);
    }
  }
}
