@import '../../../sass/fontFamilies.scss';

.permissions {
  margin: auto;
  margin-bottom: 20px;
  &.disabled {
    opacity: 0.5;
  }
  .permissionsHeading {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: var(--border);
    margin-bottom: 15px;
    .icon {
      margin-right: 5px;
      path {
        fill: var(--grey-4);
      }
    }
    p {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      color: var(--grey-1);
    }
  }
  .permissionLeves {
    .permissionToAll,
    .permissionToSpecific {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
      display: flex;
      cursor: pointer;
      width: fit-content;
      .radioBtn {
        margin-right: 5px;
      }
    }
    .permissionToAll {
      margin-bottom: 15px;
    }
    // .permissionToSpecific {
    // }
  }

  .sharablesList {
    margin-top: 15px;
    margin-left: 30px;
    .listHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .container {
      background: #fff;
      border: var(--border);
      border-radius: 10px;
      .shareabalItem {
        padding: 12px 14px;
        border-bottom: var(--border);
        cursor: pointer;
      }
      .feature {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 4px 0 4px 10px;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
      }
      .accordion {
        width: 100%;
        > div {
          padding-left: 30px;
        }
      }
    }
  }
}
