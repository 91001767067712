@import '../../../../sass/fontFamilies.scss';

.listHeader {
  // width: 100%;
  // overflow-y: scroll;
  // overflow-x: hidden;
  grid-template-columns: 20% 0% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1% 9.1%;
  border-bottom: var(--border);
  padding: 0 20px;
  display: grid;
  .nameContainer {
    border-right: 1px solid var(--grey-6);
    display: flex;
    padding: none;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    .name {
      font-family: $TTInterphasesMedium;
      color: var(--grey-1);
      background-color: white;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      display: flex;
      padding: none;
      justify-content: center;
      align-items: center;
    }
    .type {
      font-family: $TTInterphasesMedium;
      color: #7f8fa4;
      background-color: white;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      display: flex;
      padding: none;
      justify-content: center;
      align-items: center;
    }
  }
  .checkBoxContanier {
    height: 58px;
    font-family: $TTInterphasesMedium;
    color: #7f8fa4;
    background-color: var(--grey-7);
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-right: 1px solid var(--grey-6);

    &.disable {
      opacity: 0.6;
    }

    &.active {
      background-color: #d7f2db;
    }
  }
  .horizontalLine {
    box-shadow: 1px 0px 4px 0.5px var(--grey-1);
    // position: absolute;
    z-index: 0;
  }

  &.firstHeader {
    p {
      height: 58px;
      font-family: $TTInterphasesMedium;
      color: #7f8fa4;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      border-right: 1px solid var(--grey-6);
      background-color: white;
    }
    p:nth-child(1) {
      display: flex;
      background-color: white;
      justify-content: start;
      text-align: start;
      padding-left: 1rem;
      // padding-top: -1rem;
    }
  }
}
