.screensSkeleton {
  display: grid;
  margin-bottom: 7rem;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 20px;
  .skeletonStyles {
    height: 265px;
    border-radius: 10px;
  }
}
