@import '../sass/fontFamilies.scss';

$basePath: '../assets/fonts/';

@font-face {
  font-family: 'Inter-Thin';
  src: url($basePath + 'Inter/Inter-Thin.ttf');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url($basePath + 'Inter/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url($basePath + 'Inter/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url($basePath + 'Inter/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter-Light';
  src: url($basePath + 'Inter/Inter-Light.ttf');
}
@font-face {
  font-family: 'Inter-ExtraLight';
  src: url($basePath + 'Inter/Inter-ExtraLight.ttf');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url($basePath + 'Inter/Inter-ExtraBold.ttf');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url($basePath + 'Inter/Inter-Bold.ttf');
}
@font-face {
  font-family: 'Inter-Black';
  src: url($basePath + 'Inter/Inter-Black.ttf');
}

@font-face {
  font-family: 'Helvetica';
  src: url($basePath + 'Helvetica/Helvetica.ttf');
}
@font-face {
  font-family: 'Helvetica-Bold';
  src: url($basePath + 'Helvetica/Helvetica-Bold.ttf');
}
@font-face {
  font-family: 'Helvetica-BoldOblique';
  src: url($basePath + 'Helvetica/Helvetica-BoldOblique.ttf');
}
@font-face {
  font-family: 'helvetica-compressed';
  src: url($basePath + 'Helvetica/helvetica-compressed.otf');
}
@font-face {
  font-family: 'helvetica-light';
  src: url($basePath + 'Helvetica/helvetica-light.ttf');
}
@font-face {
  font-family: 'Helvetica-Oblique';
  src: url($basePath + 'Helvetica/Helvetica-Oblique.ttf');
}
@font-face {
  font-family: 'helvetica-rounded-bold';
  src: url($basePath + 'Helvetica/helvetica-rounded-bold.otf');
}

@font-face {
  font-family: 'TTInterfaces-ExtraLight';
  src: url($basePath + 'TTInterphases/TTInterfaces-ExtraLight.ttf');
}
@font-face {
  font-family: 'TTInterfaces-Light';
  src: url($basePath + 'TTInterphases/TTInterfaces-Light.ttf');
}
@font-face {
  font-family: 'TTInterfaces-Medium';
  src: url($basePath + 'TTInterphases/TTInterfaces-Medium.ttf');
}
@font-face {
  font-family: 'TTInterfaces-Regular';
  src: url($basePath + 'TTInterphases/TTInterfaces-Regular.ttf');
}
@font-face {
  font-family: 'TTInterfaces-Bold';
  src: url($basePath + 'TTInterphases/TTInterfaces-Bold.ttf');
}
@font-face {
  font-family: 'TTInterfaces-Black';
  src: url($basePath + 'TTInterphases/TTInterfaces-Black.ttf');
}
