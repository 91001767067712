.calenderEventsMainSection {
  flex-grow: 1;
  background: transparent;
  display: flex;
  background: #0000000a;
  height: 120%;
  border-right: var(--border);

  @media screen and (max-height: 663px) and (min-height: 555px) {
    height: 140%;
  }
  @media screen and (max-height: 554px) and (min-height: 435px) {
    height: 180%;
  }
  @media screen and (max-height: 434px) and (min-height: 300px) {
    height: 270%;
  }
}
