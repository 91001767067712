@import '../../../../sass/fontFamilies.scss';

.roleBasedHeader {
  background: #4c4ab4;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .logo {
      // margin-bottom: 35px;
      margin-left: -10px;
      margin-right: 5px;
      cursor: pointer;
      display: none;
      @media (max-width: 800px) {
        display: grid;
      }
      @media (orientation: landscape) and (max-width: 810px) {
        display: grid;
      }
    }

    .userType {
      font-size: 14px;
      margin-right: 4px;
      font-weight: 500;
      font-family: $InterMedium;
      color: #f4f5f7;
      display: inline-block;
      padding: 11px;
      cursor: pointer;
      border-radius: 8px;

      &.active {
        background: #403aa5;
      }

      &:hover {
        background: #403aa5;
      }
    }

    .clientsContainer {
      display: flex;
      align-items: center;
      padding-left: 4px;
      .clients {
        font-size: 13px;
        color: #c8c7e8;
        font-family: $InterRegular;
        display: inline-block;
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      .expandArrowRight {
        margin: 0 5px;
      }

      &.active {
        background: #403aa5;
        border-radius: 8px;
      }
    }
    .clientName {
      color: #fffcef;
      font-size: 13px;
      font-family: $InterRegular;
    }
    .emptyState {
      font-size: 13px;
      color: #c8c7e8;
      font-family: $InterRegular;
      display: inline-block;
      margin-left: 4px;
      padding: 12px;
      border-radius: 8px;
      cursor: pointer;
      transition: 400ms;
      &:hover {
        background: #403aa5;
      }
      &.active {
        background: #403aa5;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    @media screen and (max-width: 800px) {
      .clients,
      .expandArrowRight,
      .expandArrowDown,
      .clientName {
        display: none;
      }
    }
  }
  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .moreIcon {
      cursor: pointer;
      path {
        fill: #fff;
      }
      display: none;
      @media screen and (max-width: 800px) {
        display: none;
      }
      // @media screen and (max-width: 800px) {
      //   display: block;
      // }
    }
  }
}
