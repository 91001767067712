@import '../../../../sass/fontFamilies.scss';

.container {
  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      width: 22px;
      margin-right: 7px;
    }
    span {
      color: var(--bluey-grey);
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .optionsList {
    margin-left: 20px;
    list-style: none;
    .list {
      padding-top: 10px;
      padding-bottom: 9px;
      padding-left: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      font-weight: 500;
      color: var(--grey-1);
      border-radius: 10px;
      line-height: 19.6px;
      cursor: pointer;
      transition: 400ms;
      margin-bottom: 5px;
      &.active {
        font-family: $InterMedium;
        background: var(--screenz-main-hover);
        color: var(--screenz-main);
      }
      &:hover {
        color: var(--screenz-main);
        background: var(--screenz-main-hover);
      }
    }
  }
}
