.foldersSkeleton {
  padding: 15px 20px 0 20px;
  > span {
    grid-gap: 10px;
    display: flex;
  }
  .folder {
    width: 130px;
    height: 50px;
  }
}
