@import '../../../sass/fontFamilies.scss';
.remoteWrapper {
  visibility: visible;
  opacity: 1;
  @media screen and (min-width: 800px) {
    visibility: hidden;
    opacity: 0;
    .onResponsive {
      display: none;
    }
  }
  .remoteIcon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    &.offline {
      path:nth-child(1),
      path:nth-child(5) {
        fill: #f0f3f8;
      }
      path:nth-child(2),
      path:nth-child(3),
      path:nth-child(4) {
        fill: #d8dae9;
      }
    }
  }
  .controllerPopover {
    right: -15px;
    top: 40px;
    max-width: 300px;
    min-width: 300px;
    padding: 20px;
    border-radius: 8px;
    @media screen and (max-width: 800px) {
      display: none;
    }
    .popoverWrapper {
      .remoteHeaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .remoteTitle {
          font-size: 21px;
          font-weight: 500;
          color: #111216;
          font-family: $InterRegular;
        }
      }
    }
  }
}
