@import '../../../../../../../../../sass/fontFamilies.scss';

.notSetup {
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  right: 18px;
  background-color: var(--white);
  color: var(--fail);
  font-size: 11px;
  right: 16px;
  top: 10px;

  @media screen and (max-width: 800px) {
    margin-bottom: 4px;
  }
  border: solid 1px var(--fail);
  &.enabled {
    border: solid 1px var(--success);
    color: var(--success);
  }
}
