.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .eyeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  // .capitalize {
  //   text-transform: capitalize;
  // }
  .input {
    // background: #e8effd;

    border: 1px solid #aaadc4;
    color: #111216 !important;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    &.hideBorder {
      border: none;
    }
    &::after {
      content: 'E';
      right: -20px;
      height: 10px;
      width: 10px;
      background: red;
    }
    &:focus {
      outline: none;
      border-color: #615ae2;
      color: #111216 !important;
    }
    &.disabled {
      background: #d9e8f7;
    }
    &.error {
      border: 1px solid var(--fail);
      background: #fef1f2 !important;
    }
  }
}
