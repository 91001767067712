@import '../../../../sass/fontFamilies.scss';

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: var(--border);
  .left {
    .heading {
      display: flex;
      align-items: center;
      cursor: pointer;
      // text-transform: capitalize;
      .arrowIcon {
        transform: rotate(180deg);
      }
      p {
        font-family: $InterMedium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: var(--grey-1);
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    .btnStyle {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
    }
    .removeButton {
      border-radius: 8px;
      background: #f6e4e6;
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      color: var(--fail);
      border: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      :focus {
        outline: none;
      }
      .distbinIcon {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        path {
          fill: var(--fail);
        }
      }
    }
  }
}
