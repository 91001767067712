@import '../../../../../sass/fontFamilies.scss';

.mainContainer {
  margin: 2vh;
  padding: 0;
  p {
    color: red;
    font-family: $InterMedium;
    font-size: 7.9px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--grey-3);
    margin-bottom: 2vh;
  }
  .labelAndInput {
    width: 70%;
    height: 20vh;
    border: solid 1px var(--grey-5);
    background-color: var(--white);
    border-radius: 6px;
    position: relative;
    resize: none;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-4);
    font-family: $InterMedium;
    outline: none;
  }
  .labelAndInput::placeholder {
    position: absolute;
    top: 5%;
    left: 1%;
    font-size: 14px;
    color: var(--grey-4);
    font-family: $InterMedium;
  }
}
