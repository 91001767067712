@import '../../../../sass/fontFamilies.scss';
.timeZoneDropdownContainer {
  @media screen and (min-width: 800px) {
    // max-width: 460px;
    // width: 185%;
  }
  .dropdownChoosedOption {
    display: flex;
    align-items: center;
    border: solid 1px var(--grey-5);
    padding: 0 6px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    .icon {
      width: 24px;
      height: 22px;
      margin-right: 5px;
    }
    .option {
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .dropdownIcon {
      width: 11px;
      margin-left: auto;
      margin-right: 3px;
      rotate: 180deg;
    }
    .timezoneInputField {
      padding: 10px 2px 10px 5px;
    }
  }
  .popoverClassName {
    width: 100%;
    @media screen and (min-width: 800px) {
      // max-width: 460px;
      //   width: 145%;
      //   margin-left: -30%;
      //   right: -218px;
      bottom: 38px;
    }
    border-radius: 8px;
    .viewOptions {
      width: 100%;
      list-style-type: none;
      max-height: 180px;
      @media screen and (min-width: 800px) {
        max-height: 250px;
      }
      overflow: auto;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      & :last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .option {
        padding: 10px;
        color: var(--grey-1);
        font-size: 14px;
        font-family: $InterRegular;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 29px;
          margin-right: 10px;
        }
        &:hover {
          background: var(--screenz-main-hover);
        }
      }
    }
  }
}
