@import '../../../../sass/fontFamilies.scss';

.listAndDetails {
  min-height: 100%;
  flex-grow: 1;
  overflow: auto;

  @media screen and (max-width: 500px) {
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 800px) {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;

    &.displayboth {
      grid-template-columns: 1fr 340px;
    }
  }
  .foldersAndScreensWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 129px;
    max-height: 100%;
    overflow: auto;
    // @media screen and (max-width: 800px) {
    //   max-height: 100%;
    //   overflow: auto;
    // }
    .foldersWrapper {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      padding: 15px 20px 10px 20px;
    }
    .heading {
      font-family: $InterMedium;
      margin: 3px 0 -9px 20px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.32px;
      text-align: left;
      color: var(--grey-1);
    }
    .screenList {
      padding: 20px;
      flex-grow: 1;
      &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
      }

      .sharedPlaylist {
        width: 100%;
        margin-top: 30px;
      }
      .heading {
        font-family: $InterRegular;
        margin: 0 0 -15px 8px;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.32px;
        text-align: left;
        color: var(--grey-1);
      }
      .playlistTable {
        @media screen and (max-width: 800px) {
          display: none;
        }
        tbody {
          height: 100% !important;
          max-height: unset;
        }
      }
      .forMobile {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }

      .playlistTable2 {
        @media screen and (max-width: 800px) {
          display: none;
        }
        tbody {
          height: 100% !important;
          max-height: unset;
        }
      }
      .forMobile {
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }

    .sharedFlag {
      font-family: $InterRegular;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--white);
      position: relative;
      width: 15%;
      padding: 5px;
      border-radius: 5px;
      background-color: var(--success);
      color: white;
      left: 23vw;
      top: 5.5vw;
    }

    @media screen and (max-width: 800px) {
      display: none;
      &.Playlists {
        display: block;
        height: 80%;
        overflow-y: auto;

        &.fitHeight {
          height: auto;
        }

        &.fitFoldersHeight {
          height: 41vh;
        }
      }
    }
  }
  .detailView {
    height: 100%;
    margin-top: -1.5px;
    @media screen and (min-width: 800px) {
      &.hide {
        display: none;
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
      &.PlaylistsDetails {
        display: flex;
        overflow: auto;
        height: 42vh;
      }
    }
  }
}
