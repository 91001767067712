@import '../../../sass/fontFamilies.scss';

.titleContainer {
  min-width: 820px;
  max-width: 860px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    width: 50%;
    border: 1px solid #d8dae9;
    // @media screen and (min-width: 800px) {
    //   border: 1px solid #615ae2;
    // }
    &.focused {
      border-image-source: linear-gradient(97deg, #9f77ed -23%, #635ce6 45%);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff),
        linear-gradient(97deg, #9f77ed -23%, #635ce6 45%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 0px 0px 0px 3px #cdcdf2;
    }
    .searchIcon {
      cursor: pointer;
      margin-left: 10px;
      height: 14px;
      width: 14px;
    }
    .inputField {
      border: none;
    }
  }
}
