@import '../../sass/fontFamilies.scss';

.deleteModal {
  @media screen and (min-width: 800px) {
    min-width: 460px;
  }
}

.delayMessage {
  text-align: center;
  font-size: 16px;
  font-family: $InterRegular;
  color: #33373a;
  display: inline-block;
  padding: 15px 0;
  max-width: 460px;
  width: 100vw;
}
.confirmationMsg {
  font-family: $InterRegular;
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  padding: 10px 0px;
}
.deleteWarn {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f8eaec;
  display: flex;
  align-items: center;

  .warnIcon {
    width: 22px;
    height: 20px;
    margin-right: 10px;
  }
  .message {
    font-family: $InterMedium;
    font-size: 11px;
    font-weight: 500;
    color: var(--fail);
  }
}

.labelParagraph {
  font-family: $InterRegular;
  font-size: 14px;
  margin-bottom: 1rem;
  max-width: 460px;
}
.deleteModal {
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
    width: 100vw;
  }
  .labelAndInput {
    margin-bottom: 10px;
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: center;
    }
    margin-bottom: 15px;
    p {
      text-transform: capitalize;
    }
    .label {
      font-size: 13px;
      font-family: $InterRegular;
      color: #33373a;
      display: inline-block;
      width: 102px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      flex-grow: 1;
      font-size: 14px;
      font-family: $InterRegular;
      background: transparent;
      color: #33373a;
      @media screen and (min-width: 800px) {
        width: auto;
      }
    }
  }
}
