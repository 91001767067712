@import '../../../../../sass/fontFamilies.scss';

.moreDrawerWrapper {
  background: #f4f5f7;
  height: 0;
  overflow: auto;
  transition: 300ms;
  pointer-events: auto;
  @media screen and (min-width: 48rem) {
    display: none;
  }
  &.open {
    height: calc(100% - 87px);
    // height: 80vh;
  }
  .profileWrapper {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    background: #fff;
    border-bottom: 1px solid #d8dae9;
    cursor: pointer;
    &:hover {
      background: var(--screenz-main-hover);
    }
    .avatar {
      margin-right: 10px;
    }
  }

  .drawerHeading {
    background: #615ae2;
    padding: 16px 15px;
    font-size: 16px;
    font-weight: 500;
    font-family: $InterMedium;
    color: #fff;
  }
  .profileWrapper {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    background: #fff;
    border-bottom: 1px solid #d8dae9;
    .profilePic {
      width: 40px;
      height: 40px;
      margin: 0 9px 0 0;
      border-radius: 100%;
      > img {
        height: inherit;
        width: inherit;
        border-radius: inherit;
        object-fit: cover;
      }
    }
    > p {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: #111216;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #111216;
    font-family: $InterMedium;
    border-bottom: 1px solid #d8dae9;
    img {
      margin-right: 5px;
    }
    p {
      flex-grow: 1;
    }
  }
  .helpCenter {
    border-top: 1px solid #d8dae9;
  }
  .companies {
    background: #fff;
    padding: 0 10px;
    .companyHeading {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      background: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #111216;
      font-family: $InterMedium;
      // border-bottom: 1px solid #d8dae9;
      img {
        margin-right: 5px;
      }
      p {
        flex-grow: 1;
      }
    }
    .companiesPopover {
      max-height: 200px;
      overflow-y: scroll;
      // border: 4px solid var(--grey-7);
    }
    .company {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      font-family: $InterRegular;
      color: #111216;
      padding: 15px 10px;
      border-bottom: 1px solid #d8dae9;
      p {
        flex-grow: 1;
        padding: 4px 0;
      }
      &.active {
        color: #615ae2;
        font-family: $InterMedium;
      }
    }
    &.last {
      padding-bottom: 20px;
    }
  }
}
