@import '../../sass/fontFamilies.scss';

.connectionStatusPill {
  .disconnect {
    margin-right: 3px;
    width: 18px;
    height: 18px;
  }
  background: #acaec5;
  border-radius: 5px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-family: $InterRegular;
  &.online {
    background: #36af47;
  }
}
