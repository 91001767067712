/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  width: 16px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  height: 16px;
  width: 16px;
}

input[type='checkbox'] {
  visibility: hidden;
}

/* Create a custom checkbox */
.checkmark {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--screenz-main);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  width: 65%;
  min-height: 65%;
  border-radius: 100%;
  background-color: var(--screenz-main);
}

.container .checkmarkstyle:after {
  left: 4px;
  width: 6px;
  height: 12px;
  // border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.main {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  width: 16px;

  input[type='checkbox'] {
    visibility: hidden;
  }

  .checkmarkstyle {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    border: 1px solid #d8dae9;
    border-radius: 3px;
  }

  input:checked ~ .checkmarkstyle {
    background-color: var(--screenz-main);
  }

  .checkmarkstyle:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmarkstyle:after {
    display: block;
  }

  .checkmarkstyle:after {
    // left: 8px;
    bottom: 3px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
