.calenderEventsMainSection {
  flex-grow: 1;
  background: transparent;
  display: flex;
  background: #0000000a;
  height: 100%;
  @media screen and (max-height: 663px) and (min-height: 555px) {
    height: 120%;
  }
  @media screen and (max-height: 554px) and (min-height: 435px) {
    height: 150%;
  }
  @media screen and (max-height: 434px) and (min-height: 300px) {
    height: 210%;
  }
}
