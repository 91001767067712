@import '../../../../../../../../../sass/fontFamilies.scss';
.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .avatarCircle {
    width: 36px;
    height: 36px;
    background-color: #615ae2;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    margin-right: 5px;
    .avatarText {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      font-family: $InterRegular;
      font-size: 14px;
      color: #ffffff;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 100%;
        box-shadow: none;
      }
    }
  }
  .nameContainer {
    padding: 0;
    .nameDetails {
      font-size: 14px;
      font-family: $InterRegular;
      line-height: 1.4;
      font-weight: normal;
      color: #111216;
    }
    .emailDetails {
      font-size: 13px;
      font-family: $InterRegular;
      text-align: left;
      color: #8a8daa;
    }
  }
}
