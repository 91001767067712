@import '../../../sass/fontFamilies.scss';

.contentPreview {
  height: 100%;
  .contentDrawerHeader,
  .playListDrawerHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    background: var(--grey-7);
    border-radius: 8px 0 0 0;
    border-bottom: solid 1px var(--grey-6);
    height: 9.49%;
    @media only screen and (min-width: 1440px) and (min-height: 900px) {
      height: 8%;
    }
    @media only screen and (min-width: 1280px) and (min-height: 950px) {
      height: 7.5%;
    }
    .colOne {
      display: flex;
      align-items: center;
      .previewHeading {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--grey-3);
        margin-left: 10px;
      }
    }
    .colTwo {
      display: flex;
      align-items: center;

      .closeIcon {
        cursor: pointer;
      }
    }
  }
  .playListDrawerHeader {
    height: 10.5%;
    @media only screen and (min-width: 1440px) and (min-height: 900px) {
      height: 10.4%;
    }
    @media only screen and (min-width: 1280px) and (min-height: 950px) {
      height: 8.5%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 42px;
    flex-grow: 1;
    overflow: auto;
    height: 80%;
    overflow: auto;
    .info {
      margin-bottom: 14px;
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
    }
    .imageContentPreview {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      img,
      video {
        width: 100%;
      }
    }
    .contentName {
      font-family: $TTInterphasesBold;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 20px;
    }
    .details {
      border-collapse: separate;
      border-spacing: 0 8px;
      tr {
        .heading {
          color: var(--bluey-grey);
          font-family: $InterRegular;
          font-size: 13px;
        }
        .value {
          font-family: $InterRegular;
          font-size: 13px;
          color: var(--grey-1);
        }
      }
    }
  }
}
