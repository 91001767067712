@import '../../../../sass/fontFamilies.scss';

.featureWithSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding-left: 10px;
  border-top: var(--border);
  &:last-child {
    border-bottom: var(--border);
  }
  .feature {
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  .switchBtn {
    width: 54px;
    height: 24px;
    border-radius: 30px;
    background: var(--grey-5);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    padding: 2px;
    transition: 300ms;
    &.active {
      justify-content: flex-end;
      background: var(--screenz-main);
    }
    .nob {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: #fff;
      &::after {
        content: 'Off';
        position: absolute;
        right: -22px;
        top: 3px;
        font-family: $InterMedium;
        font-size: 11px;
        font-weight: 500;
        color: var(--white);
      }
      &.active {
        &::before {
          content: 'On';
          position: absolute;
          left: -22px;
          top: 3px;
          font-family: $InterMedium;
          font-size: 11px;
          font-weight: 500;
          color: var(--white);
        }
        &::after {
          content: '';
        }
      }
    }
  }
}
