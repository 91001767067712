@import '../../../../../sass/fontFamilies.scss';

.rightSidebar {
  width: 60px;
  padding: 6px;
  height: 100%;
  @media screen and (max-height: 663px) and (min-height: 555px) {
    height: 120%;
  }
  @media screen and (max-height: 554px) and (min-height: 435px) {
    height: 150%;
  }
  @media screen and (max-height: 434px) and (min-height: 300px) {
    height: 210%;
  }
  border-left: var(--border);
  &.hideSideBarBorder {
    border-left: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .templateIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .menuContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      .templatesIcon {
        width: 22px;
        height: 22px;
        path {
          fill: var(--grey-4);
        }
      }
      &.active {
        background: rgba(97, 90, 226, 0.2);
        .templatesIcon {
          path {
            fill: var(--screenz-main);
          }
        }
      }
    }
    .menu {
      font-family: $InterMedium;
      font-size: 11px;
      font-weight: 500;
      margin-top: 0.5vh;
      color: var(--grey-4);
      &.active {
        color: var(--grey-1);
      }
    }
  }
}
