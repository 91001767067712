@import '../../../../sass/fontFamilies.scss';

.addWebContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  .mainSection {
    flex-grow: 1;
    background: #f4f5f7;
    grid-template-columns: 1fr 398px;
    @media screen and (min-width: 800px) {
      display: grid;
    }
    .previewContainer {
      background: #fff;
      border: var(--border);
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-bottom: 20px;
      padding-bottom: 10px;

      @media screen and (min-width: 800px) {
        margin: 50px auto;
        border-radius: 8px;
        width: 80%;
        &.portait {
          width: 35%;
          min-width: 380px;
        }
        &.landscape {
          width: 80%;
        }
      }
      .previewHeader {
        height: 30px;
        background: var(--grey-7);
        padding: 10px;
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        border-bottom: var(--border);
        .leftOne {
          flex-grow: 1;
          display: flex;
          align-items: center;
          .browserBtns {
            display: block;
            width: 11px;
            height: 11px;
            border-radius: 100px;
            background: var(--grey-5);
            margin-right: 6px;
          }
        }
        .rightOne {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .refreshBtn {
            display: flex;
            align-items: center;
            background: transparent;
            cursor: pointer;
            border: 0;
            &:active {
              outline: none;
              background: transparent;
              box-shadow: none;
            }
            .refreshIcon {
              width: 18px;
              height: 18px;
              margin-right: 6px;
            }
            span {
              font-family: $InterMedium;
              font-size: 11px;
              font-weight: 500;
              color: var(--grey-1);
            }
          }
        }
      }
      .mainSection {
        background: #fff;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 800px) {
          height: 50vh;
        }
        .previewIFrame {
          width: 100%;
          height: 100%;
        }
        img {
          width: 35%;
        }
        .addUrl {
          font-family: $InterMedium;
          font-size: 21px;
          font-weight: 500;
          letter-spacing: -0.42px;
          color: var(--grey-1);
          margin-bottom: 15px;
        }
        .addUrlDescription {
          font-family: $InterRegular;
          font-size: 14px;
          line-height: 1.4;
          text-align: center;
          color: var(--grey-1);
          max-width: 308px;
        }
      }
    }
    .respnsiveBtns {
      display: flex;
      padding: 20px;
      padding-bottom: 40px;
      @media screen and (min-width: 800px) {
        display: none;
      }
    }
  }
}
