@import '../../sass/fontFamilies.scss';

.mainContainer {
  padding: 0;
  margin: 0;

  @media screen and (max-width: 799px) {
    margin-top: 36vh;
  }
  .errorContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 40vh;
    margin-bottom: 27px;
    margin-top: 20%;
    .errorMessage {
      font-family: $InterSemiBold;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 33px;

      @media screen and (max-width: 799px) {
        padding: 5vw;
        font-size: 26px;
        margin-bottom: 18px;
      }
    }
  }
}
