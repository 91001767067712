@import '../../../../sass/fontFamilies.scss';

.wrapper {
  user-select: none;
  // background: var(--white);
  height: 100%;
  pointer-events: none;
  // temp
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.hide {
    @media screen and (max-width: 799px) {
      height: 0%;
      display: none;
    }
  }
  @media screen and (min-width: 800px) {
    position: static;
  }
  @media (max-width: 799px) {
    order: 2;
    // position: absolute;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }
  .sidebar {
    pointer-events: auto;
    background: #fff;
    @media (min-width: 800px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    padding: 20px;
    border-right: var(--border);
    position: relative;
    @media (max-width: 800px) {
      box-shadow: 0 0px 11px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      padding: 15px 20px 30px 20px;
      background: #fff;
      justify-content: space-between;
    }
    @media (orientation: landscape) and (max-width: 810px) {
      bottom: 0;
      width: 100%;
      display: flex;
      padding: 10px 20px 10px 20px;
      background: #fff;
      justify-content: space-between;
      order: 2;
    }
    .logo {
      margin-bottom: 35px;
      cursor: pointer;
      @media (max-width: 800px) {
        display: none;
      }
      @media (orientation: landscape) and (max-width: 810px) {
        display: none;
      }
    }
    .icon {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 400ms;
      cursor: pointer;
      @media (max-width: 800px) {
        margin: 0;
        width: 80px;
      }
      @media (orientation: landscape) and (max-width: 810px) {
        margin: 0;
      }
      &.active {
        &:hover {
          .iconHolder {
            @media (min-width: 800px) {
              background: #615ae2;
            }
            .svgStyle {
              path {
                @media (max-width: 800px) {
                  fill: #615ae2;
                }
                fill: #fff;
              }
            }
          }
          .name {
            color: #111216;
          }
        }
      }

      .iconHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 100%;
        margin-bottom: 5px;
        transition: 400ms;
        @media (min-width: 800px) {
          width: 40px;
          height: 40px;
          background: #f0f3f8;
        }
        @media (orientation: landscape) and (max-width: 810px) {
          width: 30px;
          height: 30px;
          background: #f0f3f8;
        }
        &.active {
          @media (min-width: 800px) {
            background: #615ae2;
          }

          .svgStyle {
            path {
              fill: #fff;
              @media (max-width: 800px) {
                fill: #615ae2;
              }
            }
          }
        }
        .svgStyle {
          @media (max-width: 800px) {
            width: 24px;
            height: 24px;
          }
          @media (orientation: landscape) and (max-width: 810px) {
            width: 20px;
            height: 20px;
          }
          path {
            fill: #acaec5;
          }
        }
      }
      .name {
        font-size: 11px;
        color: #8a8daa;
        font-family: $InterRegular;
        cursor: pointer;
        &.active {
          color: #111216;
        }
      }
    }
    .moreIcon {
      display: none;
      z-index: 100;
      @media (max-width: 800px) {
        display: flex;
      }
      @media (orientation: landscape) and (max-width: 810px) {
        display: flex;
      }

      &.active {
        @media (min-width: 800px) {
          background: #615ae2;
        }

        .svgStyle {
          path {
            fill: #fff;
            @media (max-width: 800px) {
              fill: #615ae2;
            }
          }
        }
      }
    }
  }
}
