@import '../../../../sass/fontFamilies.scss';

.button {
  font-size: 14px;
  font-weight: 500;
  font-family: $InterMedium;
  padding: 10px;
  border: 0;
  margin-right: 5px;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: 400ms;
  color: var(--grey-3);
  &.active {
    color: #615ae2;
    background: #c6d3f9;
  }
  &:hover {
    color: #615ae2;
  }
}
