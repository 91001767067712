@import '../../../../sass/fontFamilies.scss';

.rolesTable {
  border: 1px solid red;
  background: #fff;
  border: var(--border);
  border-radius: 10px;
  padding-bottom: 8px;
  .tableHead {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: var(--border);
    .roleName,
    .lastUpdated {
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.15;
      letter-spacing: 0.2px;
      color: #7f8fa4;
      white-space: nowrap;
    }
    .roleName {
      padding-left: 40px;
    }
    .lastUpdated {
      padding-right: 20px;
    }
  }
  .tableBody {
    max-height: 400px;
    overflow: auto;
    .row {
      display: grid;
      grid-template-columns: 3fr 1fr;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: var(--border);
      cursor: pointer;
      .nameValue,
      .lastUpdatedValue {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
        white-space: nowrap;
      }
      .nameValue {
        padding-left: 40px;
        text-transform: capitalize;
      }
      .lastUpdatedValue {
        padding-right: 20px;
      }
    }
  }
}
