@import '../../sass/fontFamilies.scss';
.layoutWrapper {
  height: 100%;
  display: flex;
  background: var(--grey-bg);
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
  @media screen and (max-width: 810px) and (orientation: landscape) {
    flex-direction: column;
  }
  .clientsListInLayout {
    margin: 10px;
  }
  @media screen and (min-width: 800px) {
    .clientsListInLayout,
    .folderPath {
      display: none;
    }
  }
  .bodyWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    .folderName {
      padding: 8px 10px;
      background: var(--white);
      border-bottom: var(--border);
      display: flex;
      font-size: 14px;
      align-items: center;
      @media screen and (min-width: 800px) {
        display: none;
      }
      p {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        color: var(--grey-1);
      }
    }
    .child {
      flex-grow: 1;
      overflow: auto;
    }
  }
}
