@import '../../../../../sass/fontFamilies.scss';

.disable2FA {
  width: 80vw;
  max-width: 412px;
  padding-bottom: 16px;
  p {
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: #000;
  }
}
