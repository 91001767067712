@import '../../sass/fontFamilies.scss';

.modalStyles {
  section {
    border-radius: 0 0 10px 10px;
  }
}

.addTemplateModal {
  width: 100%;
  padding-bottom: 45px;
  @media screen and (min-width: 800px) {
    max-width: 638px;
    width: 100vw;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  .template {
    cursor: pointer;
    .templatePreview {
      display: flex;
      justify-content: left;
      margin-bottom: 5px;
      .standardMeetingRoom {
        width: 90%;
        height: 100%;
        // border-radius: 10px;

        &.extraWidth {
          background: #d9d9d9;
        }
      }
    }
    .name {
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.15;
      letter-spacing: 0.2px;
      color: #33373a;
      margin-bottom: 5px;
    }
    .description {
      font-family: $InterMedium;
      font-size: 11px;
      font-weight: 500;
      color: var(--grey-4);
    }
  }
}
