@import '../../../../../sass/fontFamilies.scss';

.footer {
  display: flex;
  justify-content: flex-end;
  background: var(--white);
  padding: 15px;
  grid-gap: 15px;
  // margin-bottom: 2.5rem;
  @media screen and (min-width: 800px) {
    display: none;
  }
  .btns {
    font-size: 15px;
    font-weight: 500;
    font-family: $InterMedium;
  }
  @media screen and (min-width: 800px) {
    border-radius: 0 0 10px 10px;
    padding: 15px;
  }
}
