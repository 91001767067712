@import '../../../../../../sass/fontFamilies.scss';

.eventContainer {
  margin-top: 40px;
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 1;
  width: 100%;
  height: 40vw;
  @media screen and (min-width: 1200px) {
    height: 40vw;
  }
  .main {
    position: relative;
    height: 85%;
    // @media screen and (max-height: 660px) {
    //   height: 95%;
    //   // margin-bottom: 1rem;
    // }
    .eventBgImg {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .eventDetails {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-image: linear-gradient(226deg, #1e6143, transparent 700%);
      background-blend-mode: darken;
      padding: 20px;
      display: flex;
      flex-direction: column;
      opacity: 0.9;

      &.ongoing {
        background-image: linear-gradient(226deg, #d8354b, transparent 700%);
      }

      .sectionOne {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        .currentTime {
          border: dashed 1.5px rgba(255, 255, 255, 0.6);
          border-radius: 10px;
          padding: 10px 14px;
          min-height: 6.1vw;
          max-height: 91px;
          min-width: 12.3vw;
          &.showActive {
            border: solid 2px #4aacdd;
          }
          .time {
            font-family: $InterMedium;
            font-size: 2.5vw;
            // font-size: 38px;
            font-weight: 500;
            color: var(--white);
            text-align: right;
          }
          .date {
            font-family: $InterRegular;
            font-size: 1.25vw;
            // font-size: 18px;
            color: var(--white);
            text-align: center;
          }
        }
      }
      .sectionTwo {
        border: dashed 1.5px rgba(255, 255, 255, 0.6);
        flex-grow: 1;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &.showActive {
          border: solid 2px #4aacdd;
        }
        .subject {
          font-family: $InterSemiBold;
          font-size: 4.05vw;
          // font-size: 59.4px;
          font-weight: 600;
          color: var(--white);
          line-height: 1;
          margin-bottom: 15px;
        }
        .meetingTime {
          font-family: $InterMedium;
          font-size: 2.45vw;
          // font-size: 36px;
          font-weight: 500;
          color: var(--white);
          line-height: 1;
          margin-bottom: 8px;
        }
        .description {
          font-family: $InterMedium;
          font-size: 2.18vw;
          // font-size: 32px;
          font-weight: 500;
          color: var(--white);
          line-height: 1;
          margin-bottom: 8px;
        }
        .available {
          font-family: $InterMedium;
          font-size: 2.45vw;
          // font-size: 36px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);
          line-height: 2vw;
        }
        .organizer {
          font-family: $InterMedium;
          font-size: 1.635vw;
          // font-size: 24px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);
          line-height: 1;
        }
        .bookingDetails {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: auto;
          gap: 0.7rem;

          .bookNow {
            font-size: 24px;
            font-family: $InterMedium;
            color: rgba(255, 255, 255, 0.7);
          }
          .activateBookingBtn {
            margin-top: auto;
            font-family: $InterMedium;
            // font-size: 24px;
            font-size: 1.6vw;
            font-weight: 500;
            text-align: center;
            width: 7.2vw;
            height: 3.75vw;
            color: #0f8c54;
            border-radius: 7.8px;
            background-color: var(--white);
            padding: 10px;
            border: 1px solid #0f8c54;
          }
        }
        .activateBookingBtn {
          margin-top: auto;
          font-family: $InterMedium;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          color: #d8354b;
          border-radius: 7.8px;
          background-color: var(--white);
          padding: 10px 20px;
          border: 1px solid #d8354b;
        }
      }
    }
  }
  .footer {
    height: 20%;
    background: #000;
    display: grid;
    @media screen and (max-height: 660px) {
      height: 25%;
    }
    align-items: center;
    grid-template-columns: 45% 55%;
    padding: 10px 35px;
    .logoHolder {
      width: 222px;
      height: 100%;
      max-height: 70px;
      img {
        // width: 100%;
        height: 100%;
      }
    }

    .nextMeeting {
      display: flex;
      // width: 100px;
      .subject {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 320px;
        justify-content: center;
        margin-right: 10px;
        // grid-gap: 10px;
        // @media screen and (max-width: 1330px) {
        //   grid-template-columns: 35% 72%;
        // }
        .heading {
          font-family: $InterRegular;
          font-size: 15px;
          color: #eeefef;
          text-align: right;
        }
        .schedule {
          // display: grid;
          // grid-template-columns: 25% 80%;
          align-items: center;
          // grid-gap: 10px;
          @media screen and (max-width: 1337px) {
            // grid-template-columns: 35% 80%;
          }
          :nth-child(1) {
            font-family: $InterSemiBold;
            // font-size: 19px;
            font-weight: 600;
            text-align: right;
            // @media screen and (min-width: 1337px) {
            //   text-align: left;
            // }
            color: #a5a5a5;
          }
        }
        .organizer {
          display: grid;
          align-items: center;
          @media screen and (max-width: 1337px) {
          }
          :nth-child(1) {
            font-family: $InterSemiBold;
            font-size: 16px;
            color: #a5a5a5;
            text-align: right;
            // @media screen and (min-width: 1337px) {
            //   text-align: left;
            // }
          }
        }
      }
      .value {
        font-family: $InterMedium;
        font-size: 22px;
        line-height: 1.1;
        // width: 50%;
        color: #a5a5a5;
      }
    }
  }
}
