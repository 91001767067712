@import '../../../../../../../sass/fontFamilies.scss';

.nameContainer {
  padding: 15px 0;
  .label {
    margin-bottom: 10px;
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-1);
  }
  .customNameInput {
    border: solid 1px var(--grey-5);
    background-color: var(--white);
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.4;
    color: var(--grey-1);
  }
}
