@import '../../sass/fontFamilies.scss';

.screenTile {
  position: relative;
  cursor: pointer;
  user-select: none;
  margin: 10px;
  @media screen and (min-width: 800px) {
    height: 265px;
  }
  &:hover {
    .remoteOnHover {
      visibility: visible;
      z-index: 10000;
      opacity: 1;
      position: relative;
    }
  }
  .topSection {
    display: flex;
    border-radius: 10px 10px 0 0;
    @media screen and (min-width: 800px) {
      display: block;
    }

    .screenNameHolder {
      padding: 10px;
      border: 1px solid #d8dae9;
      background: #fff;
      border-bottom: 0px;
      border-radius: 0 10px 0 0;
      flex-grow: 1;
      .clientName {
        margin-bottom: 25px;
        font-size: 14px;
        color: #111216;
        font-family: $InterRegular;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 35vw;
        @media screen and (min-width: 450px) {
          max-width: 40vw;
        }
      }
      @media screen and (min-width: 800px) {
        text-align: center;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .clientName {
          margin-bottom: 2px;
          max-width: unset;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .layoutName {
        display: flex;
        align-items: center;
        width: 133px;
        justify-content: center;
        > img {
          margin-right: 5px;
          width: 18px;
        }
        > span {
          font-size: 13px;
          color: #8a8daa;
          font-family: $InterRegular;
          white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          overflow-wrap: break-word;
        }
        .contentSpan {
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        .contentMobileSpan {
          @media screen and (min-width: 800px) {
            display: none;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    height: 34px;
    border: 1px solid #d8dae9;
    border-radius: 0 0 10px 10px;
    background: #ffffff80;
    .assignToScreen {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      padding: 6px;
      color: #5b5d70;
      font-size: 13px;
      font-family: $InterRegular;
      > img {
        margin-left: 5px;
      }
    }
    .left {
      .deviceName {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #8a8daa;
        font-family: $InterRegular;
        // text-transform: capitalize;
        > img {
          margin-right: 5px;
        }
      }
    }
    // .right {
    //   .connectionStatusPill {
    //     .disconnect {
    //       margin-right: 3px;
    //     }
    //     background: #acaec5;
    //     border-radius: 5px;
    //     padding: 3px 5px;
    //     display: flex;
    //     align-items: center;
    //     font-size: 13px;
    //     color: #fff;
    //     font-family: $InterRegular;
    //   }
    // }
  }
  transition: 400ms;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 2px 10px 0 rgba(138, 141, 170, 0.65);
  }
}
