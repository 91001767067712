@import '../../../../sass/fontFamilies.scss';

.contentDetails {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // border-top: 0;
  margin-top: 0;
  @media screen and (min-width: 800px) {
    margin-top: 0;
    background: #fff;
    min-width: 312px;
    border: var(--border);
    border-bottom: none;
    border-right: none;
  }
  .headingSection {
    width: 100%;
    margin-top: 0;
    padding: 8px;
    border-bottom: var(--border);
    display: none;

    > button {
      background: #dfdef9;
      color: #615ae2;
      padding: 10px;
      border: 0;
      border-radius: 8px;
      font-family: $InterMedium;
      cursor: pointer;
    }
    @media screen and (min-width: 800px) {
      display: block;
    }
  }
  .folderIcon {
    margin: auto;
    margin-top: 45px;
    margin-bottom: 5px;
    width: 90px;
  }
  .paragraphOne {
    margin-bottom: 25px;
    font-size: 21px;
    text-align: center;
    font-family: $InterRegular;
    color: #111216;
  }
  .shareAccess {
    padding: 15px;
  }
}
