@import '../../../../sass/fontFamilies.scss';

.personalInformation {
  border-bottom: var(--border);
  padding: 20px 10px;
  background: var(--white);
  @media screen and (min-width: 800px) {
    padding: 0;
  }
  .sectionHeading {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .firstAndLastName {
    display: block;
    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-bottom: 18px;
    }
  }
  .inputEmail,
  .labelAndInput {
    margin-bottom: 10px;
    p {
      font-family: $InterRegular;
      font-size: 13px;
      color: var(--grey-3);
      line-height: 18.2px;
      margin-bottom: 5px;
    }
    input {
      background: var(--white);
      border: var(--border);
      color: var(--grey-1);
      font-family: $InterRegular;
    }
    .email {
      @media screen and (min-width: 800px) {
        margin-bottom: 35px;
      }
    }
  }
  .inputEmail {
    input {
      background: #f4f5f7;
      color: #8a8daa;
    }
  }
}
