@import '../../../../../../sass/fontFamilies.scss';

.eventContainer {
  margin-top: 40px;
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 1;
  width: 100%;
  height: 40vw;
  @media screen and (min-width: 1200px) {
    height: 40vw;
  }
  .main {
    position: relative;
    height: 100%;
    @media screen and (max-height: 660px) {
      height: 100%;
      // margin-bottom: 1rem;
    }
    .eventBgImg {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .eventDetails {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      // background-image: linear-gradient(226deg, #000, transparent 300%);
      background-blend-mode: lighten;
      background-color: rgba(21, 22, 33, 0.9);
      // background-image: linear-gradient(to bottom, #1e6143, #1e6143);
      padding: 20px;
      display: flex;
      flex-direction: column;
      opacity: 0.9;

      &.ongoing {
        background: #000;
        background-image: linear-gradient(226deg, #000, transparent 300%);
        // background-image: linear-gradient(226deg, #d8354b, transparent 700%);
      }

      .sectionOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.7vw;
        row-gap: 1vw;
        // justify-content: flex-end;
        margin-bottom: 20px;
        .currentTime {
          border: dashed 1.5px rgba(255, 255, 255, 0.6);
          border-radius: 10px;
          height: 5.35vw;
          min-width: 10vw;

          .meetingContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .subjectInfo {
              display: flex;
              align-items: baseline;
              padding-top: 0.6vw;
              justify-content: space-between;

              .meetingInfoAndDetails {
                display: flex;
                align-items: baseline;
                .meetingInfo {
                  font-size: 19.8px;
                  font-size: 1.4vw;
                  font-family: $InterBold;

                  color: #1e6143;

                  &.ongoing {
                    color: #d8354b;
                  }
                }

                .roomDetails {
                  color: rgba(255, 255, 255, 0.65);
                  // font-size: 10.9px;
                  font-size: 0.8vw;
                  font-family: $InterRegular;
                }
              }

              .meetingTime {
                font-family: $InterBold;
                // font-size: 19.8px;
                font-size: 1.38vw;
                font-weight: 500;
                color: var(--white);
                line-height: 1;
                color: #eeefef;
                margin-bottom: 0.36vw;
                // margin-bottom: 5px;
              }
            }
            .description {
              font-family: $InterRegular;
              // font-size: 13.5px;
              font-size: 0.9vw;
              font-weight: 500;
              color: var(--white);
              line-height: 1;
              margin-bottom: 5px;
              margin-bottom: 0.36vw;
            }
            .orgainzerContainer {
              display: flex;
              justify-content: space-between;
              .organizer {
                font-family: $InterRegular;
                font-size: 10.9px;
                // font-size: 0.75vw;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.65);
                line-height: 1;
              }
              .subject {
                display: flex;
                .heading {
                  font-family: $InterRegular;
                  // font-size: 10.9px;
                  font-size: 0.8vw;
                  padding-left: 2px;
                  font-weight: 500;
                  color: rgba(255, 255, 255, 0.65);
                  line-height: 1;
                }
              }
            }
          }

          .logoHolder {
            width: 30%;
            height: 100%;
            max-height: 70px;
            padding: 0px 0px 10px 0px;
            img {
              // width: 100%;
              height: 100%;
            }
          }
          &.showActive {
            border: solid 2px #4aacdd;
          }
          .time {
            font-family: $InterBold;
            // font-size: 30.2px;
            font-size: 2.08vw;
            font-weight: 500;
            color: var(--white);
            text-align: start;

            &.rightAligned {
              text-align: end;
            }
          }
          .date {
            font-family: $InterRegular;
            // font-size: 11.5px;
            font-size: 0.78vw;
            color: var(--white);
            text-align: start;

            &.rightAligned {
              text-align: end;
            }
          }
        }
      }
      .sectionTwo {
        border: dashed 1.5px rgba(255, 255, 255, 0.6);
        flex-grow: 1;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &.showActive {
          border: solid 2px #4aacdd;
        }
        .subject {
          font-family: $InterSemiBold;
          font-size: 4.05vw;
          // font-size: 59.4px;
          font-weight: 600;
          color: var(--white);
          line-height: 1;
          margin-bottom: 15px;
        }
        .meetingTime {
          font-family: $InterMedium;
          font-size: 2.45vw;
          // font-size: 36px;
          font-weight: 500;
          color: var(--white);
          line-height: 1;
          margin-bottom: 8px;
        }
        .description {
          font-family: $InterMedium;
          font-size: 2.18vw;
          // font-size: 32px;
          font-weight: 500;
          color: var(--white);
          line-height: 1;
          margin-bottom: 8px;
        }
        .available {
          font-family: $InterMedium;
          font-size: 2.45vw;
          // font-size: 36px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);
          line-height: 2vw;
        }
        .organizer {
          font-family: $InterMedium;
          font-size: 1.635vw;
          // font-size: 24px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);
          line-height: 1;
        }
        .activateBookingBtn {
          margin-top: auto;
          font-family: $InterMedium;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          color: #d8354b;
          border-radius: 7.8px;
          background-color: var(--white);
          padding: 10px 20px;
        }
      }
    }
  }
  .footer {
    height: 20%;
    background: #000;
    display: grid;
    @media screen and (max-height: 660px) {
      height: 25%;
    }
    align-items: center;
    grid-template-columns: 45% 55%;
    padding: 10px 35px;
    .logoHolder {
      width: 222px;
      height: 100%;
      max-height: 70px;
      img {
        // width: 100%;
        height: 100%;
      }
    }

    .nextMeeting {
      .subject {
        display: grid;
        grid-template-columns: 25% 80%;
        align-items: center;
        grid-gap: 10px;
        @media screen and (max-width: 1330px) {
          grid-template-columns: 35% 72%;
        }
        .heading {
          font-family: $InterRegular;
          font-size: 15px;
          color: #eeefef;
          text-align: right;
        }
        .value {
          font-family: $InterMedium;
          font-size: 24px;
          line-height: 1.1;
          color: #a5a5a5;
        }
      }
      .schedule {
        display: grid;
        grid-template-columns: 25% 80%;
        align-items: center;
        grid-gap: 10px;
        @media screen and (max-width: 1337px) {
          grid-template-columns: 35% 80%;
        }
        :nth-child(1) {
          font-family: $InterSemiBold;
          font-size: 16px;
          font-weight: 600;
          text-align: right;
          // @media screen and (min-width: 1337px) {
          //   text-align: left;
          // }
          color: #a5a5a5;
        }
      }
      .organizer {
        display: grid;
        grid-template-columns: 25% 80%;
        align-items: center;
        grid-gap: 10px;
        @media screen and (max-width: 1337px) {
          grid-template-columns: 35% 80%;
        }
        :nth-child(1) {
          font-family: $InterSemiBold;
          font-size: 16px;
          color: #a5a5a5;
          text-align: right;
          // @media screen and (min-width: 1337px) {
          //   text-align: left;
          // }
        }
      }
    }
  }
}
