@import '../../../../../sass/fontFamilies.scss';

.playlistContents {
  user-select: none;
  height: 45vh;
  overflow: auto;
  @media screen and (min-width: 800px) {
    height: 65vh;
    overflow: auto;
    // height: calc(100% - 284px);
    // overflow-y: auto;
  }
  > div {
    // height: 100%;
    // overflow: auto;
    padding-top: 15px;
    @media screen and (min-width: 800px) {
      padding-left: 8vw;
      padding-right: 8vw;
      // height: 80vh;
    }
    @media (min-width: 1080px) and (max-width: 1280px) {
      // height: calc(100vh + 2vh);
    }
    // &::-webkit-scrollbar {
    //   width: 0; /* width of the entire scrollbar */
    // }
    .playlistContentSkeleton {
      height: 72px;
      margin-bottom: 10px;
      border-radius: 8px;
    }
  }
  .lastChild {
    &:last-child {
      height: 1px;
    }
  }
  .content {
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
    background: var(--white);
    border-radius: 8px;
    border: solid 1px var(--grey-6);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    &:last-child {
      padding-bottom: 20rem;
    }
    &:hover {
      background: var(--screenz-main-hover);
    }
    .colOne {
      display: flex;
      align-items: center;
      .preview {
        width: 82.3px;
        height: 53.6px;
        margin-right: 10px;
        margin-top: 0.3rem;
        img {
          // width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
      .contentNameAndType {
        .contentName {
          font-family: $InterRegular;
          font-size: 16px;
          color: #33373a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 15vw;
          @media screen and (max-width: 800px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50vw;
          }
        }
        .contentType {
          font-family: $InterRegular;
          font-size: 16px;
          color: #7f8fa4;
        }
      }
    }
    .colTwo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .videoDuration {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-4);
        margin-left: -82px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
      }
      .timePickers {
        align-items: center;
        grid-gap: 5px;
        display: none;
        @media screen and (min-width: 800px) {
          display: flex;
          margin-left: -10rem;
        }
      }
      .dustbinIcon {
        width: 20px;
        height: 24px;
        @media screen and (min-width: 800px) {
          display: none;
        }
      }
    }

    @media screen and (min-width: 800px) {
      &:hover {
        .colTwo {
          // gap: 1rem;
          .timePickers {
            // margin-left: 0;
            z-index: 1;
          }
          .dustbinIcon {
            display: flex;
            margin-right: 0.2rem;
            z-index: 9;
            opacity: 0.6;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
