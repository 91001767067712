@import '../../sass/fontFamilies.scss';

.pageNotfound {
  height: 100%;
  .logo {
    margin-bottom: 25px;
  }
  .pageNotfoundImg {
    width: 267px;
    height: 216.2px;
    margin-bottom: 27px;
  }
  .errorMessage {
    font-family: $InterSemiBold;
    font-size: 36px;
    font-weight: 600;
    max-width: 521px;
    text-align: center;
    margin-bottom: 33px;
  }
  .gotoScreenBtn {
    font-family: $InterMedium;
    font-size: 15px;
    font-weight: 500;
  }
}
