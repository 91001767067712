.ellipseEmpty {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: solid 2px var(--grey-5);
  background-color: var(--white);
  cursor: pointer;
}

.ellipseFill {
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  background-color: var(--success);
}
