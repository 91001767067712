@import '../../sass/fontFamilies.scss';

.addClient {
  // width: 100vw;
  @media screen and (min-width: 800px) {
    // max-width: 460px;
  }
  @media screen and (max-width: 1020px) and (orientation: landscape) {
    width: 100vw;
  }

  .labelAndInput {
    margin-bottom: 15px;
    .label {
      font-size: 13px;
      font-family: $InterRegular;
      margin-bottom: 10px;
      color: #5b5d70;
    }
    .input {
      font-family: $InterRegular;
      font-size: 14px;
      // text-transform: none;
      color: var(--grey-1);
      &.errorExist {
        margin-bottom: 10px;
      }
    }
  }
  .subModalCotanier {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-top: 1px solid #d8dae9;

    .subModalSubCotanier {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .subTitle {
        font-size: 15px;
        font-family: $InterRegular;
        text-align: left;
        margin-left: 5px;
        color: var(--grey-1);
      }
    }
    .checkBoxContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .checkBox {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border-radius: 3px;
      }
      .welcomeNotification {
        font-size: 13px;
        margin-left: 0.5rem;
        font-family: $InterRegular;
        color: var(--grey-1);
      }
    }
  }
}
