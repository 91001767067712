@import '../../sass/fontFamilies.scss';

.addScreen {
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
    width: 100vw;
  }
  .delayMessage {
    text-align: center;
    font-size: 16px;
    font-family: $InterRegular;
    color: #33373a;
    display: inline-block;
    padding: 15px 0;
  }
  .labelAndInput {
    margin-bottom: 10px;
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: center;
    }
    margin-bottom: 15px;
    .label {
      font-size: 13px;
      font-family: $InterRegular;
      color: #33373a;
      display: inline-block;
      width: 102px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      flex-grow: 1;
      font-size: 14px;
      font-family: $InterRegular;
      background: transparent;
      color: #33373a;
      @media screen and (min-width: 800px) {
        width: auto;
      }
    }
    .input:-webkit-autofill,
    .input:-webkit-autofill:hover,
    .input:-webkit-autofill:focus,
    .input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
}
