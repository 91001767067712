@import '../../../../../sass/fontFamilies.scss';

.details {
  background: var(--white);
  border-left: 1px solid var(--grey-6);
  @media screen and (max-width: 799px) {
    display: none;
  }
  .header {
    padding: 11px 15px;
    border-left: 1px solid var(--grey-6);
    border-bottom: 1px solid var(--grey-6);
    height: 61px;
    align-items: center;
    display: none;
    @media screen and (min-width: 800px) {
      display: flex;
      margin-left: -1px;
    }
    .playlistsIcon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
    .heading {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      color: #354052;
    }
  }
  .screenNameAndHashCode {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    grid-template-columns: 2fr 60px;
    grid-gap: 11px;
    padding: 15px 20px 27px 20px;
    border-bottom: 1px solid var(--grey-6);
    @media screen and (max-width: 800px) {
      border-top: 1px solid var(--grey-6);
    }
    .playlistName {
      p {
        font-size: 13px;
        font-family: $InterMedium;
        color: #354052;
        // margin-bottom: 12px;
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
      input {
        background: var(--white);
        color: var(--grey-1);
        padding: 8px 10px;
      }
    }
    .saveBtn {
      background: var(--grey-7);
      padding: 10px 15px;
      &:disabled {
        background: var(--grey-6);
      }
      // opacity: 0.6;
      // border: 1px solid var(--grey-5);
    }
  }
  .orientationTypeAndSize {
    padding: 10px;
    hr {
      margin-right: -10px;
      background-color: var(--grey-6);
      border: 0;
      height: 0.5px;
    }
    .sizeContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .size {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        color: #354052;
      }
      .btnContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .changeResolutionBtn {
          padding: 6px 8px;
        }
        .arrowIcon {
          transform: rotate(-90deg);
          width: 21px;
          margin-left: 12px;
        }
      }
    }
    .activeOrientationContainer {
      display: flex;
      align-items: center;
      padding-top: 10px;
      height: 40px;
      .screenIcon {
        path {
          fill: var(--grey-4);
        }
      }
      .screenOrientation {
        margin-left: 7px;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-4);
      }
    }
  }
  .colorPicker {
    padding: 10px 15px;
    border-bottom: var(--border);
    border-left: 1px solid var(--grey-6);
    p {
      font-family: $InterMedium;
      color: var(--grey-1);
    }
    display: none;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .mutateOption {
    display: flex;
    align-items: center;
    padding: 18px 15px;
    border-bottom: var(--border);
    border-left: 1px solid var(--grey-6);
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    font-family: $InterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--grey-1);

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .shareAccess {
    padding: 15px;
  }
}
