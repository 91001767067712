@import '../../../../../sass/fontFamilies.scss';

.screenDetails {
  height: 100%;
  background: var(--grey-bg);
  display: flex;
  flex-direction: column;
  .mainContent {
    flex-grow: 1;
    grid-template-columns: 1fr 500px;
    display: none;
    overflow: hidden;
    @media screen and (min-width: 800px) {
      display: grid;
    }
    .details {
      border-left: 1px solid var(--grey-6);
    }
    .colOne {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .main {
        flex-grow: 1;
        overflow: auto;
        padding: 20px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 20px;
        position: relative;
        grid-auto-rows: minmax(min-content, max-content);
        &::-webkit-scrollbar {
          width: 0;
        }

        .remote {
          max-height: 500px;
          padding: 20px;
          border-radius: 10px;
          border: 1px solid var(--grey-6);
          position: sticky;
          top: 0;
        }
      }
    }
  }
  .responsiveView {
    background: var(--grey-bg);
    @media screen and (min-width: 800px) {
      display: none;
    }
    .screenshot {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid var(--grey-6);
    }
    .pingTime {
      margin-bottom: 200px;
    }
    .remote {
      padding: 20px 10px;
      margin-bottom: 200px;
      hr {
        margin-left: -10px;
        margin-right: -10px;
      }
    }
    .btnsContainer {
      display: flex;
      justify-content: flex-end;
      grid-gap: 10px;
      background: var(--white);
      padding: 14px 10px;
    }
  }
}
