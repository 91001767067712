@import '../../../../../sass/fontFamilies.scss';

.menu {
  display: flex;
  align-items: center;
  padding: 15px 18px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  color: #111216;
  font-family: $InterMedium;
  border-bottom: 1px solid #d8dae9;
  cursor: pointer;
  &:hover {
    background: var(--screenz-main-hover);
  }
  img {
    margin-right: 5px;
  }
  p {
    flex-grow: 1;
  }
}
