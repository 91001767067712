@import '../../../../sass/fontFamilies.scss';

.featuresWithRadioBtns {
  display: flex;
  cursor: pointer;
  height: 20px;
  padding-left: 10px;
  margin-bottom: 13px;

  &.disabled {
    opacity: 0.5;
  }
  .radioBtn {
    margin-right: 5px;
  }
  .feature {
    font-family: $InterRegular;
    font-size: 14px;
    line-height: 1.4;
    color: var(--grey-1);
  }
}
