@import '../../../sass/fontFamilies.scss';

.usersList {
  padding-right: 15px;
  margin-right: -22px;
  &.sharedWithList {
    margin: 0 -25px 0 -10px;
    min-height: 250px;
    max-height: 300px;
    padding-bottom: 10vh;
    overflow: auto;
  }
  .avatarAndName {
    display: flex;
    align-items: center;
    margin: 13px 10px;
    cursor: pointer;
    .avatarWithEmail {
      display: flex;
    }
    .name {
      margin-left: 10px;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
      // text-transform: capitalize;
    }
    .email {
      margin-left: 10px;
      font-family: $InterRegular;
      font-size: 13px;
      line-height: 1.4;
      color: var(--grey-4);
    }
    .dropDown {
      border: 0;
    }
    .options {
      accent-color: red;
    }
    .owner {
      margin-left: auto;
      font-family: $InterRegular;
      font-size: 14px;
      line-height: 1.4;
      color: var(--grey-1);
    }
    .optionPopover {
      // background: #4c4ab4;
      border-radius: 8px;
      min-width: 100px;
      right: 0px;
      // padding: 13px;
      // cursor: pointer;
      .logout {
        display: flex;
        cursor: pointer;
        align-items: center;
        font-size: 14px;
        padding: 5px 15px;
        // margin-bottom: 0.8rem;
        font-family: $InterRegular;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        &:hover {
          background: var(--screenz-main-hover);
          border-radius: 8px;
          // margin-
        }

        &:last-child {
          &:hover {
            // background: var(--screenz-main-hover);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &:first-child {
          &:hover {
            // background: var(--screenz-main-hover);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    .canEdit {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-family: $InterRegular;
      font-size: 14px;

      cursor: pointer;
      padding: 4px;
      border-radius: 6px;
      &.show {
        background: #3b369a;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
      .profilePic {
        margin-right: 8px;
      }
      .username {
        font-size: 13px;
        font-family: $InterRegular;
        color: #c8c7e8;
      }
      .expandArrowDown {
        margin: 0 5px;
      }
      display: flex;
      align-items: center;
      span {
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.4;
        color: var(--grey-1);
      }
      img {
        width: 10px;
        margin-left: 14px;
      }
    }
  }
}
