@import '../../sass/fontFamilies.scss';

.currentFolder {
  padding: 15px;
  width: 100%;
  .inputAndSaveBtn {
    display: flex;
    grid-gap: 10px;
    .saveBtn {
      border: 1px solid var(--grey-6);
      &:hover {
        background: var(--screenz-main-hover);
        color: #4e48b5;
      }
      &:disabled {
        background: transparent;
        opacity: 0.8;
        cursor: default;
      }
    }
  }
  .sectionHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .heading {
      font-family: $InterMedium;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      color: #354052;
    }

    .morePopupContainer {
      .popover {
        right: 0;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
        &.last {
          top: -40px;
        }
      }
      .morePopup {
        right: 0;
        display: flex;
        flex-direction: column;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.36;
        color: var(--grey-1);

        .option {
          padding: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
          &:hover {
            background: var(--screenz-main-hover);
            border-radius: 8px;
          }

          &.disabled {
            background-color: rgb(239, 239, 239);
            opacity: 0.4;
          }
        }
        &.webContentMutation {
          .option {
            margin-bottom: 0;
          }
        }
      }
      .moreBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 300ms;
        width: 30px;
        .icon {
          path {
            fill: var(--grey-4);
          }
        }
        &:hover {
          background: var(--grey-6);
          border-radius: 5px;
          cursor: pointer;
          .icon {
            path {
              fill: var(--grey-3);
            }
          }
        }
      }
    }
  }
  .folderNameInput {
    font-family: $InterRegular;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.2px;
    color: var(--grey-1);
    padding: 8px;
  }
}
