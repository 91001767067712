@import '../../../../../../../../../sass/fontFamilies.scss';
.playlists {
  margin: 0 -10px;
  background: var(--white);
  width: 100%;
  .avatarContainer {
    display: flex;
    padding: 0 15px;
    margin: 0 -10px;
    .avatarCircle {
      width: 36px;
      height: 36px;
      background-color: #d8dae9;
      text-align: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      margin: 10px 5px 10px 10px;
      .avatarText {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        font-family: $InterRegular;
        font-size: 14px;
        color: #ffffff;
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: fill;
          border-radius: 100%;
          box-shadow: none;
        }
      }
    }
    .nameCotainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .span,
      .nameDetails {
        font-size: 14px;
        font-family: $InterRegular;
        line-height: 1.4;
        font-weight: normal;
        color: #111216;
        margin-top: 10px;
        padding-right: 2px;
      }
    }
    .emailDetails {
      font-size: 13px;
      font-family: $InterRegular;
      text-align: left;
      color: #8a8daa;
    }
  }
  .dividerRow {
    margin-left: 15%;
    border-top: 1px solid var(--grey-6);
    margin-right: -10px;
  }
  .permissionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fullAccess {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 12%;
      .permissionDetails {
        margin: 10px 5px 10px 10px;
        .permissionAccess,
        .permissionTitle {
          position: relative;
          font-family: $InterRegular;
          font-size: 11px;
          font-weight: 500;
          color: #7c8fa6;
        }
        .permissionAccess {
          color: #5b5d70;
        }
      }
    }
    .notSetupContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .fA {
        position: relative;
        font-family: $InterRegular;
        font-size: 11px;
        font-weight: 500;
        color: #7c8fa6;
        margin-bottom: 2.5vh;
        // margin-right: 2px;
      }
    }
    .morePopupContainer {
      .popover {
        right: 0;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      }
      .morePopup {
        right: 0;
        border-radius: 8px;
        display: flex;
        align-items: flex-start;
        padding: 10px;
        font-family: $InterRegular;
        font-size: 14px;
        line-height: 1.36;
        color: var(--grey-1);
        img {
          width: 24px;
          height: 24px;
        }
        p {
          white-space: nowrap;
        }
        &:hover {
          background: var(--screenz-main-hover);
        }
      }
      .moreBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 300ms;
        margin-right: 5px;

        .icon {
          path {
            fill: var(--grey-4);
          }
        }
        &:hover {
          // background: var(--grey-6);
          border-radius: 5px;
          .icon {
            path {
              fill: var(--grey-3);
            }
          }
        }
      }
    }
  }
  .teamMemberDivider {
    border-bottom: 1px solid var(--grey-6);
    margin-right: -10px;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
}
