@import '../../../sass/fontFamilies.scss';

.templatePreview {
  height: 100%;
  .drawerHeader {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
    height: 60px;
    background: var(--grey-7);
    border-radius: 8px 0 0 0;
    border-bottom: solid 1px var(--grey-6);
    .colOne {
      display: flex;
      align-items: center;
      .templateIcon {
        width: 24px;
        height: 24px;
        flex-grow: 0;
        margin-right: 6px;
      }
      .previewHeading {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        color: #354052;
      }
    }
    .colTwo {
      display: flex;
      align-items: center;

      .closeIcon {
        cursor: pointer;
      }
    }
  }
  .hr {
    margin: 20px -20px;
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    // padding-top: 42px;
    flex-grow: 1;
    overflow: auto;
    height: 80%;
    .templateHeading {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
      p {
        font-family: $InterMedium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: var(--grey-1);
      }
    }
    .inputAndSaveBtn {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
        padding: 10px;
        border-radius: 8px;
        border: solid 1px var(--grey-5);
        font-family: $InterRegular;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0.2px;
        color: var(--grey-1);

        &:focus {
          outline: none;
        }
      }
      .saveBtn {
        font-family: $InterMedium;
        font-size: 14px;
        font-weight: 500;
        color: var(--grey-4);
        padding: 8px 15px;
        border: solid 1px var(--grey-5);
        &:hover {
          background: var(--screenz-main-hover);
        }
        &:disabled {
          background: transparent;
          opacity: 0.8;
          cursor: default;
        }
        &:hover {
          color: #4e48b5;
        }
      }
    }
    .standardMeetingRoom {
      width: 100%;
      max-width: 271px;
      margin-bottom: 12px;
    }

    .editWebPageBtn {
      width: 132px;
      height: 36px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: var(--screenz-main-hover);
      }
    }
  }
}
