.playlistView {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .clientsListInLayout {
    margin: 10px;
  }
  @media screen and (min-width: 800px) {
    .clientsListInLayout {
      display: none;
    }
  }
  .mainContent {
    flex-grow: 1;
    grid-template-columns: 1fr 340px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .mobileSubheader {
      border: 0;
    }
    @media screen and (min-width: 800px) {
      display: grid;
      .mobileSubheader {
        display: none;
      }
    }
    .colOne {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .desktopSubheader {
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
      .main {
        flex-grow: 1;

        overflow: auto;
        padding: 0 0 0 0;
        grid-gap: 20px;
        position: relative;
        &::-webkit-scrollbar {
          // width: 0;
        }

        @media screen and (min-width: 800px) {
          // padding: 0 8vw;
        }

        .remote {
          max-height: 500px;
          padding: 20px;
          border-radius: 10px;
          border: 1px solid var(--grey-6);
          position: sticky;
          top: 0;
        }
        .playlistsList {
          overflow: auto;
          height: 100%;
        }
        @media screen and (max-width: 800px) {
          padding-top: 0;
        }
      }
      @media screen and (max-width: 800px) {
        display: none;
        margin-top: 62px;
        padding-top: 10px;
        &.Playlist {
          display: flex;
        }
      }
    }
    .detailView {
      // height: 100%;
      // height: 100vh;
      margin-top: -1px;
      &.isLoading {
        height: 73vh;
      }
      @media screen and (max-width: 800px) {
        display: none;
        &.PlaylistDetails {
          display: block;
        }
      }
    }
  }
}
