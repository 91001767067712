@import '../../../../sass/fontFamilies.scss';

.companyInfoWrapper {
  background: var(--white);
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
    border: var(--border);
    padding: 20px 10px;
  }
  .sectionHeading {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .labelAndInput {
    margin-bottom: 10px;
    p {
      font-family: $InterRegular;
      font-size: 13px;
      color: var(--grey-3);
      line-height: 18.2px;
      margin-bottom: 5px;
    }
    input {
      background: var(--white);
      border: var(--border);
      color: var(--bluey-grey);
      font-family: $InterRegular;
      &:focus {
        outline: none;
        border-color: #615ae2;
      }
    }
  }
  .inputContainer {
    display: flex;
    grid-gap: 10px;
    width: 100%;
    .postCodeInput {
      max-width: 93px;
      margin-bottom: 10px;
    }
    .cityInput {
      width: 100%;
    }
  }
  .btnsContainer {
    display: flex;
    grid-gap: 10px;
    padding: 10px 0;
    .saveChangeBtn {
      font-family: $InterMedium;
      font-size: 15px;
      font-weight: 500;
      border: none !important;
    }
  }
}
