@import '../../../../sass/fontFamilies.scss';

.table {
  margin-bottom: 35px;
  border: solid 1px var(--grey-6);
  background: var(--white);
  border-radius: 10px;
  padding-bottom: 7px;
  width: 75%;
  .rows {
    .row {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr;
      @media screen and (min-width: 800px) {
        grid-template-columns: 6fr 3fr 3fr;
      }
      &:hover {
        cursor: pointer;
        background: var(--screenz-main-hover);
      }
      .cell {
        font-family: $InterRegular;
        border-bottom: solid 1px var(--grey-6);
        font-size: 14px;
        color: var(--grey-1);
        padding: 20px 0;
        &.firstCell {
          padding-left: 20px;
        }
        &.languages {
          display: flex;
          grid-gap: 10px;
          .language {
            width: 33px;
            // height: 20px;
            padding: 3px 0 2px 0;
            border-radius: 5px;
            font-family: $InterMedium;
            font-size: 11px;
            font-weight: 500;
            display: flex;
            // align-items: center;
            justify-content: center;
            &.no {
              color: var(--success);
              border: solid 1.4px var(--success);
              align-items: center;
            }
            &.en {
              border: solid 1.4px var(--fail);
              color: var(--fail);
              align-items: center;
            }
          }
        }
      }
    }
  }
}
